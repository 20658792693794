import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface RichTextEditorProps {
	value?: ReactQuill.Value;
	onChange?(
		value: string,
		delta: any,
		source: any,
		editor: ReactQuill.UnprivilegedEditor
	): void;
	readOnly?: boolean;
	className?: string;
}

export default function RichTextEditor(props: RichTextEditorProps) {
	const modules = {
		toolbar: [
			["bold", "italic", "underline", "strike"], // toggled buttons
			["blockquote"],

			[{ header: 1 }, { header: 2 }], // custom button values
			[{ list: "ordered" }, { list: "bullet" }],
			[{ indent: "-1" }, { indent: "+1" }], // outdent/indent

			[{ size: ["small", false, "large", "huge"] }], // custom dropdown
			[{ header: [1, 2, 3, 4, 5, 6, false] }],

			[{ font: [] }],
			[{ align: [] }],

			["clean"],
		],
	};

	const formats = [
		"header",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
	];

	return (
		<ReactQuill
			theme="snow"
			value={props.value}
			onChange={props.onChange}
			modules={!props.readOnly ? modules : { toolbar: false }}
			formats={formats}
			className={props.className}
			readOnly={props.readOnly}
		/>
	);
}

import { Country, NationalityType } from "../config/Types/PlaceEnumDefinitions";
import { MedicalDivision } from "../config/Types/MedicalEnumDefinitions";

// Convert enum to percentage
export const enumToPercentage = (value: any, type: object) =>
	(value / (Object.keys(type).length / 2 - 1)) * 100;

export const enumToString = (enumObject: { [x: string]: any }, value: any) => {
	const key = Object.keys(enumObject).find((k) => enumObject[k] === value);
	return key ? key : "";
};

// Formtat url
export const formatUrlLink = (url?: string) =>
	url
		? url.startsWith("http://") || url.startsWith("https://")
			? url
			: "https://" + url
		: "";

// Calculate age from DateTime
export const getAge = (birthDate: string) =>
	Math.floor(
		((new Date() as any) - new Date(birthDate).getTime()) / 3.15576e10
	);

// Handle on change for DropDown
export const handleOnChange =
	(stateValue: any, setStateValue: any, isStateHasCountryFiled?: boolean) =>
	(e: any) => {
		const { name, value } = e.target;

		isStateHasCountryFiled && name === "nationality"
			? setStateValue({ ...stateValue!, [name]: value.toString(), state: 0 })
			: isStateHasCountryFiled && name === "country"
				? setStateValue({ ...stateValue!, [name]: value, state: 0 })
				: setStateValue({ ...stateValue!, [name]: value });
	};

// Determine which action is being performed
export type AlertAction = (typeof AlertAction)[keyof typeof AlertAction];
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertAction = {
	NONE: "NONE",
	PUBLISH: "PUBLISH",
	SAVE: "SAVE",
	EDIT: "EDIT",
	REMOVE: "REMOVE",
	HIDE: "HIDE",
	UPDATE: "UPDATE",
	PREVIEW: "PREVIEW",
};

// We added them in the PlaceEnumDefinitions.ts file (or Backend)
export const listOfCountriesWithStates = [
	Country.Switzerland,
	Country.Germany,
	Country.Austria,
];

export const Nationalities: Record<NationalityType, string[]> = {
	[NationalityType.SWISS]: [
		"ch",
		"switzerland",
		"schweiz",
		"suisse",
		"svizzera",
		"svizra",
		"suisa",
		"suiza",
		"swiss",
	],
	[NationalityType.GERMAN]: [
		"de",
		"germany",
		"deutschland",
		"allemagne",
		"alemania",
		"german",
	],
	[NationalityType.AUSTRIAN]: [
		"at",
		"austria",
		"österreich",
		"autriche",
		"austriaco",
		"austriac",
		"austrian",
	],
};

export const listOfDivisionWithJobType = [
	MedicalDivision.Pharmacist,
	MedicalDivision.Physician,
	MedicalDivision.MedicalAssistant,
];

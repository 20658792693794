import { FormControlLabel, Switch } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { toggleNotificationsAPI } from "../../core/apiFunctions";
import ModalContent from "../../utils/ModalContent";
import { NotificationType } from "../../config/Types/GeneralEnumDefinitions";

type Props = {
	isOpen: boolean;
	handleClose: () => void;
	isEmailEnabled: boolean;
	isPushEnabled: boolean;
	setIsEmailEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	setIsPushEnabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const NotificationSetting: React.FC<Props> = ({
	isOpen,
	handleClose,
	isEmailEnabled,
	setIsEmailEnabled,
	isPushEnabled,
	setIsPushEnabled,
}) => {
	const { t } = useTranslation();

	const toggleEmailNotification = () => {
		setIsEmailEnabled(!isEmailEnabled);
		toggleNotificationsAPI(NotificationType.Email).catch(() =>
			setIsEmailEnabled(isEmailEnabled)
		);
	};

	const togglePushNotification = () => {
		setIsPushEnabled(!isPushEnabled);
		toggleNotificationsAPI(NotificationType.Push).catch(() =>
			setIsPushEnabled(isPushEnabled)
		);
	};

	return (
		<>
			<ModalContent
				title={t("notifications")}
				size="xs"
				show={isOpen}
				onClose={handleClose}
			>
				<div style={{ padding: "8px" }}>
					<FormControlLabel
						control={
							<Switch
								checked={isEmailEnabled}
								onChange={toggleEmailNotification}
							/>
						}
						label={
							isEmailEnabled
								? t("emailNotifications.enabled")
								: t("emailNotifications.disabled")
						}
					/>
				</div>
				<div style={{ padding: "8px" }}>
					<FormControlLabel
						control={
							<Switch
								checked={isPushEnabled}
								onChange={togglePushNotification}
							/>
						}
						label={
							isPushEnabled
								? t("pushNotificationsApp.enabled")
								: t("pushNotificationsApp.disabled")
						}
					/>
				</div>
			</ModalContent>
		</>
	);
};

export default NotificationSetting;

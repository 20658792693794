// Login.tsx

import { Button, ButtonGroup, Container } from "@mui/material";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import images from "../../assets/images";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import DisplayErrors from "../../components/common/DisplayErrors";
import FormikTextField from "../../components/common/FormikTextField";
import ForgotPasswordContent from "../../components/forgotPassword/ForgotPasswordContent";
import ResetPasswordContent from "../../components/resetPassword/ResetPasswordContent";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import { ssoURL } from "../../config/endpoints";
import {
	existsUserAPI,
	isEmailConfirmed,
	loginAPI,
} from "../../core/apiFunctions";
import {
	checkLoggedInStatus,
	getEmail,
	getRoleId,
} from "../../core/claimFunctions";
import { getClaims, saveToken } from "../../core/handleJWT";
import i18n from "../../translations/i18n";

const Login = () => {
	const navigation = useNavigate();
	const location = useLocation();
	const { update } = useContext(AuthenticationContext);
	const { t } = useTranslation();

	const queryParams = new URLSearchParams(location.search || location.state);
	const initialEmail = queryParams.get("email") ?? getEmail(getClaims());

	const [errors, setErrors] = useState<string[]>([]);
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
	const [resetToken, setResetToken] = useState("");
	const [email, setEmail] = useState(initialEmail || "");

	useEffect(() => {
		const claims = getClaims();
		if (checkLoggedInStatus(claims)) {
			navigation("/dashboard");
		}

		i18n.changeLanguage(navigator.language.includes("de") ? "de" : "en");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const state = location.state as { error: string };
		if (state && state.error) {
			setErrors([state.error]);
		}
	}, [location]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const token = queryParams.get("token");
		const emailParam = queryParams.get("email");
		if (token) {
			setResetToken(token);
			if (emailParam) {
				setEmail(emailParam);
			}
			setShowResetPasswordModal(true);
		}
	}, [location]);

	async function submit(email: string, password: string) {
		try {
			setErrors([]);

			const response = await existsUserAPI(email);
			if (response.data.sso) {
				setErrors([t("ssoExists")]);
			} else {
				var isEmailVerified = await isEmailConfirmed(email);
				if (response.data.exists && isEmailVerified) {
					try {
						const response = await loginAPI({ email, password });
						saveToken(response.data);
						var claims = getClaims();
						update(claims);
						var role = getRoleId(claims);

						if (role === UserRoles.Recrewter || role === UserRoles.Employee) {
							if (
								location.pathname.includes("login") ||
								location.pathname.includes("auth") ||
								location.pathname === "/"
							)
								navigation("/dashboard");
							else window.location.reload();
						} else if (role === UserRoles.Admin) {
							navigation("/profile");
						} else {
							navigation("/auth", { state: { error: t("invalidRole") } });
						}
					} catch (error: any) {
						if (error?.response) {
							setErrors([t("loginFailed")]);
						} else {
							setErrors([t("loginFailed")]);
						}
					}
				} else if (response.data.exists && !isEmailVerified) {
					navigation("/verifyEmail", {
						state: { email },
					});
				} else {
					navigation("/register", { state: { email: email } });
				}
			}
		} catch (error: any) {
			setErrors(error.response?.data || [t("requestFailed")]);
		}
	}

	function handleSSOLogin(
		provider: string,
		setErrors: {
			(errors: any): void;
		}
	) {
		setErrors({});
		const returnUrl = encodeURIComponent(`${window.location.origin}/sso`);
		window.location.href = `${ssoURL}/${provider}?returnUrl=${returnUrl}`;
	}

	// Remove token and email from URL when modal is closed
	const handleResetPasswordModalClose = () => {
		setShowResetPasswordModal(false);
		const url = new URL(window.location.href);
		url.searchParams.delete("token");
		url.searchParams.delete("email");
		window.history.replaceState({}, document.title, url.toString());
	};

	return (
		<>
			<Helmet>
				<title>
					{t("login")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* Include the ForgotPasswordContent modal */}
			<ForgotPasswordContent
				isOpen={showForgotPasswordModal}
				handleClose={() => setShowForgotPasswordModal(false)}
			/>

			{/* Include the ResetPasswordContent modal */}
			<ResetPasswordContent
				isOpen={showResetPasswordModal}
				handleClose={handleResetPasswordModalClose}
				token={resetToken}
				email={email}
			/>

			{/* login wrapper start */}
			<div className="auth-wrapper login">
				<Container maxWidth="lg">
					{/* inner start */}
					<div className="inner">
						{/* left start */}
						<div className="left">
							<div className="content">
								<img
									src={images.Ellipse1}
									className="ellipse-one"
									alt="Blur Background"
								/>
								<img
									src={images.Ellipse2}
									className="ellipse-two"
									alt="Blur Background"
								/>
								<img src={images.Logo} className="logo" alt="reCREWter" />
								<h3>{t("signInDirect")}</h3>
								<span>
									{`${t("notHaveAccount")} `}
									<Link title="Register here" to="/register">
										{t("registerHere")}
									</Link>
								</span>
							</div>
							<img src={images.Illustration} alt="Illustration" />
						</div>
						{/* left end */}

						{/* right start */}
						<div className="right">
							<Formik
								initialValues={{ email: email, password: "" }}
								onSubmit={async (values) =>
									await submit(values.email, values.password)
								}
								validationSchema={yup.object({
									email: yup
										.string()
										.email(t("emailNotValid"))
										.required(t("emailRequired")),
									password: yup.string().required(t("passwordRequired")),
								})}
								validateOnBlur={false}
							>
								{(formikProps) => (
									<>
										<Form className="login">
											<FormikTextField
												displayName={t("email")}
												field="email"
												autoFocus={true}
												type="text"
												placeholder={t("placeholderEmail")}
											/>
											<FormikTextField
												displayName={t("password")}
												field="password"
												type="password"
												placeholder={t("placeholderPassword")}
											/>
											{/* Forgot Password link */}
											<div
												className="forgot-password-link"
												style={{ textAlign: "right", marginBottom: "16px" }}
											>
												<span>
													<a
														href="#forgotPassword"
														onClick={(e) => {
															e.preventDefault();
															setShowForgotPasswordModal(true);
														}}
													>
														{t("forgotPassword")}
													</a>
												</span>
											</div>
											<DisplayErrors errors={errors} />
											<Button
												variant="contained"
												title={t("login")}
												disabled={formikProps.isSubmitting}
												type="submit"
											>
												{t("login")}
											</Button>
										</Form>
										<div className="separator">
											<div />
											<span>{t("orContinueWith")}</span>
											<div />
										</div>
										<ButtonGroup
											variant="outlined"
											color="inherit"
											aria-label="Basic button group"
										>
											{/* Social login buttons */}
											<Button
												title="LinkedIn"
												onClick={() =>
													handleSSOLogin("linkedin", formikProps.setErrors)
												}
											>
												<img
													src={images.Linkedin}
													className="linkedin-sso"
													alt="LinkedIn"
												/>
											</Button>
											<Button
												title="Google"
												onClick={() =>
													handleSSOLogin("google", formikProps.setErrors)
												}
											>
												<img src={images.Google} alt="Google" />
											</Button>
										</ButtonGroup>
									</>
								)}
							</Formik>
						</div>
						{/* right end */}
					</div>
					{/* inner end */}
				</Container>
			</div>
			{/* login wrapper end */}
		</>
	);
};

export default Login;

import axios from "axios";
import {
	urlAccounts,
	urlChat,
	urlCompany,
	urlContact,
	urlEmployee,
	urlJob,
	urlRecrewter,
} from "../config/endpoints";
import * as Types from "../config/Types/GeneralEnumDefinitions";
import {
	recrewterDetailsForAdminDTO,
	userDetailsForAdminDTO,
} from "./dto/admin.models";
import {
	authenticationResponse,
	contactFormResponse,
	existsResponse,
	registerRequestDTO,
	userCredentialsDTO,
} from "./dto/auth.models";
import {
	chatDTO,
	contactDTO,
	employeeSearchFilterCountryDTO,
	messageDTO,
	sortTableDTO,
	supportDTO,
	jobDescriptionDTO,
	jobOfferDTO,
	passwordDTO,
	passwordResetDTO,
	internalJobDTO,
	employeeSearchFilterDTO,
	extendedJobOfferDTO,
	jobFilterDto,
} from "./dto/dto.models";
import {
	certificateDTO,
	companyDetailsDTO,
	educationDTO,
	fileUploadDTO,
	languageSkillDTO,
	skillDTO,
	userDetailsDTO,
	userHeaderDTO,
	userInformationDTO,
	workExperienceDTO,
	aboutMeDTO,
	userConnectionDTO,
	recrewterDTO,
	idealCandidateDTO,
} from "./dto/user.models";
import { convertDateTimeToDate } from "./helperFunctions";
import { NotificationType } from "../config/Types/GeneralEnumDefinitions";

export async function existsUserAPI(email: string) {
	return await axios.get<existsResponse>(`${urlAccounts}/exists/${email}`);
}

export async function isEmailConfirmed(email: string) {
	return (await axios.post(`${urlAccounts}/isEmailConfirmed/${email}`)).data;
}

export async function resendEmailVerificationAPI(email: string) {
	return await axios.post(`${urlAccounts}/resend-confirmation-email/${email}`);
}

export async function isProfileCompletedAPI() {
	return await axios.post(`${urlAccounts}/checkProfileCompletion`);
}

export async function isCompanyProfileCompletedAPI() {
	return await axios.post(`${urlCompany}/checkCompanyProfileCompletion`);
}

export async function isUserConnectedAPI(userId: string) {
	return await axios.post(`${urlAccounts}/isUserConnected/${userId}`);
}

export async function getUserConnectionAPI(userId: string) {
	return await axios.get<userConnectionDTO>(
		`${urlAccounts}/connection/${userId}`
	);
}

export async function registerAPI(
	credentials: userCredentialsDTO,
	choosenRole: Types.UserRoles,
	userDetails?: userDetailsDTO,
	companyDetails?: companyDetailsDTO
) {
	const payload: registerRequestDTO = {
		userCredentials: credentials,
		userDetails: userDetails || null,
		companyDetails: companyDetails || null,
	};

	return await axios.post<authenticationResponse>(
		`${urlAccounts}/register/${Types.UserRoles[choosenRole]}`,
		payload
	);
}

export async function ssoRegisterAPI(role: Types.UserRoles) {
	return await axios.post(
		`${urlAccounts}/register/${Types.UserRoles[role]}/external`,
		{},
		{
			withCredentials: true, // Include this line to send cookies with the request
		}
	);
}

export async function exchangeLoginTokenAPI() {
	return await axios.get<authenticationResponse>(
		`${urlAccounts}/exchange-login-token`,
		{
			withCredentials: true, // Include this line to send cookies with the request
		}
	);
}

export async function dashboardCountsAPI() {
	return await axios.get(`${urlAccounts}/dashboard-counts`, {
		withCredentials: true, // Include this line to send cookies with the request
	});
}

export async function getAdminIdAPI() {
	return await axios.get(`${urlAccounts}/admin-id`);
}

export async function deleteUserAPI(userId = "") {
	return await axios.delete(`${urlAccounts}/deleteAccount/${userId}`);
}

export async function loginAPI(credentials: userCredentialsDTO) {
	return await axios.post<authenticationResponse>(
		`${urlAccounts}/login`,
		credentials
	);
}

export async function submitUserDetailsAPI(userDetails: userDetailsDTO) {
	return await axios.post(`${urlAccounts}/details`, userDetails);
}

export async function getUserDetailsAPI() {
	let response = await axios.get<userDetailsDTO>(`${urlAccounts}/details`);
	response.data.dateOfBirth = convertDateTimeToDate(response.data.dateOfBirth);
	return response;
}

export async function getAboutMeAPI(userId = "") {
	return await axios.get<aboutMeDTO>(`${urlEmployee}/about-me/${userId}`);
}

export async function getDreamJobDescriptionAPI(userId = "") {
	return await axios.get<string>(
		`${urlEmployee}/dream-job-description/${userId}`
	);
}

export async function submitAboutMeAPI(aboutMe: aboutMeDTO) {
	return await axios.post(`${urlEmployee}/about-me`, aboutMe);
}

export async function getUserInformationAPI(userId: string) {
	return await axios.get<userInformationDTO>(
		`${urlAccounts}/summary/${userId}`
	);
}

export async function getDreamJobAPI(userId = "") {
	return await axios.get<internalJobDTO>(`${urlEmployee}/dream-job/${userId}`);
}

export async function submitDreamJobAPI(dreamJob: internalJobDTO) {
	return await axios.post(`${urlEmployee}/dream-job`, dreamJob);
}

export async function submitIdealCandidateAPI(
	idealCandidate: idealCandidateDTO
) {
	return await axios.put(`${urlRecrewter}/ideal-candidate`, idealCandidate);
}

export async function getHeaderInformationAPI(userId: string) {
	return await axios.get<userHeaderDTO>(`${urlAccounts}/cv-header/${userId}`);
}

export async function submitCompanyDetailsAPI(
	companyDetails: companyDetailsDTO
) {
	return await axios.post(`${urlCompany}/details`, companyDetails);
}

export async function toggleJobOfferAPI(jobOfferId: string) {
	return await axios.post(`${urlJob}/toggleJobOffer/${jobOfferId}`);
}

export async function toggleNotificationsAPI(
	notificationType: NotificationType
) {
	return await axios.post(
		`${urlAccounts}/toggle-notification/${notificationType}`
	);
}

export async function isJobOfferPublishedAPI(jobOfferId: string) {
	return await axios.post(`${urlJob}/isJobOfferPublished/${jobOfferId}`);
}

export async function getJobDescriptionAPI(jobId: string) {
	return await axios.get<jobDescriptionDTO>(
		`${urlJob}/jobDescription/${jobId}`
	);
}

export async function getDescriptionLanguages() {
	return await axios.get(`${urlJob}/getDescriptionLanguages`);
}

export async function updateJobOfferAPI(job: jobOfferDTO) {
	return await axios.post(`${urlJob}/updateJobOffer`, job);
}

export async function createJobOfferAPI(job: jobOfferDTO) {
	return await axios.post(`${urlJob}/createJobOffer`, job);
}

export async function deleteJobOfferAPI(jobOfferId: string) {
	return await axios.delete(`${urlJob}/deleteJobOffer/${jobOfferId}`);
}

export async function getCompanyDetailsByJobOfferAPI(jobOfferId = "") {
	return await axios.get<companyDetailsDTO>(
		`${urlJob}/companyDetails/${jobOfferId}`
	);
}

export async function getCompanyDetailsAPI(userId: string = "") {
	return await axios.get<companyDetailsDTO>(`${urlCompany}/details/${userId}`);
}

export async function getRecrewterFromJobOfferAPI(jobOfferId = "") {
	return await axios.get<recrewterDTO>(
		`${urlJob}/recrewterDetails/${jobOfferId}`
	);
}

export async function getRecrewterDetailsAPI() {
	return await axios.get<recrewterDTO>(`${urlRecrewter}/details`);
}

export async function getDreamJobDetailsAPI(jobOfferId = "") {
	return await axios.get<internalJobDTO>(
		`${urlJob}/dreamJobDetails/${jobOfferId}`
	);
}

export async function getCompanyNameForRecrewterAPI(userId = "") {
	return await axios.get<string>(`${urlRecrewter}/companyname/${userId}`);
}

export async function getEducationsAPI(userId = "") {
	const response = await axios.get<educationDTO[]>(
		`${urlEmployee}/educations/${userId}`
	);
	response.data.forEach((education) => {
		education.startDate = convertDateTimeToDate(education.startDate);
		education.endDate = convertDateTimeToDate(education.endDate);
	});
	return response;
}

export async function submitEducationsAPI(educations: educationDTO[]) {
	return await axios.post(`${urlEmployee}/educations`, educations);
}

export async function getLanguageSkillsAPI(userId = "") {
	return await axios.get<languageSkillDTO[]>(
		`${urlEmployee}/languages/${userId}`
	);
}

export async function submitLanguageSkillsAPI(
	languageSkills: languageSkillDTO[]
) {
	return await axios.post(`${urlEmployee}/languages`, languageSkills);
}

export async function getWorkExperienceAPI(userid = "") {
	const response = await axios.get<workExperienceDTO[]>(
		`${urlEmployee}/work-experiences/${userid}`
	);
	response.data.forEach((workExperience) => {
		workExperience.startDate = convertDateTimeToDate(workExperience.startDate);
		workExperience.endDate = convertDateTimeToDate(workExperience.endDate);
	});
	return response;
}

export async function submitWorkExperienceAPI(
	languageSkills: workExperienceDTO[]
) {
	return await axios.post(`${urlEmployee}/work-experiences`, languageSkills);
}

export async function getCertificatesAPI(userid = "") {
	const response = await axios.get<certificateDTO[]>(
		`${urlEmployee}/certificates/${userid}`
	);
	response.data.forEach((workExperience) => {
		workExperience.startDate = convertDateTimeToDate(workExperience.startDate);
		workExperience.endDate = convertDateTimeToDate(workExperience.endDate);
	});
	return response;
}

export async function submitCertificatesAPI(certificates: certificateDTO[]) {
	return await axios.post(`${urlEmployee}/certificates`, certificates);
}

export async function submitFileAPI(file: fileUploadDTO) {
	let formData = new FormData();
	formData.append("file", file.file);
	formData.append("type", file.type.toString());
	return await axios.post(`${urlAccounts}/file`, formData);
}

export async function downloadFileAPI(fileName: string, userId: string = "") {
	if (userId.length > 0) {
		return await axios.get<Blob>(`${urlAccounts}/file/${fileName}/${userId}`, {
			responseType: "blob",
		});
	}
	return await axios.get<Blob>(`${urlAccounts}/file/${fileName}`, {
		responseType: "blob",
	});
}

export async function getCompanyLogoAPI(companyId = "") {
	return await axios.get<Blob>(`${urlCompany}/logo/${companyId}`, {
		responseType: "blob",
	});
}

export async function getCompanyHeaderAPI(companyId = "") {
	return await axios.get<Blob>(`${urlCompany}/header/${companyId}`, {
		responseType: "blob",
	});
}

export async function getProfilePhotoAPI(userId = "") {
	try {
		if (userId.length === 0) {
			return await axios
				.get<Blob>(`${urlAccounts}/photo`, { responseType: "blob" })
				.catch(() => null);
		}
		return await axios
			.get<Blob>(`${urlAccounts}/photo/${userId}`, { responseType: "blob" })
			.catch(() => null);
	} catch (error) {
		return null;
	}
}

export async function getBackgroundPhotoAPI(userId = "") {
	try {
		if (userId.length === 0) {
			return await axios
				.get<Blob>(`${urlAccounts}/backgroundPhoto`, { responseType: "blob" })
				.catch(() => null);
		}
		return await axios
			.get<Blob>(`${urlAccounts}/backgroundPhoto/${userId}`, {
				responseType: "blob",
			})
			.catch(() => null);
	} catch (error) {
		return null;
	}
}

export async function getProfileHeaderAPI(userId = "") {
	if (userId.length === 0) {
		return await axios.get<Blob>(`${urlAccounts}/header`, {
			responseType: "blob",
		});
	}
	return await axios.get<Blob>(`${urlAccounts}/header/${userId}`, {
		responseType: "blob",
	});
}

export async function getProfileVideoAPI(userId: string) {
	return await axios.get<Blob>(`${urlAccounts}/video/${userId}`, {
		responseType: "blob",
	});
}

export async function getSkillsAPI(userid = "") {
	return await axios.get<skillDTO[]>(`${urlEmployee}/skills/${userid}`);
}

export async function submitSkillsAPI(skills: skillDTO[]) {
	return await axios.post(`${urlEmployee}/skills`, skills);
}

export async function getAllLanguageSkillsAPI() {
	return await axios.get<languageSkillDTO[]>(`${urlEmployee}/languages/all`);
}

export async function contactPlattformAPI(contactDetails: contactDTO) {
	return await axios.post<contactFormResponse>(
		`${urlContact}/staticContact`,
		contactDetails
	);
}

export async function supportPlattformAPI(supportDetails: supportDTO) {
	return await axios.post<contactFormResponse>(
		`${urlContact}/dynamicContact`,
		supportDetails
	);
}

export async function getAllInternalJobOffers(
	page: number,
	recordsPerPage: number,
	sortBy: string,
	sortDirection: string,
	searchTerm: string,
	JobFilter: jobFilterDto
) {
	sortBy = sortBy.length > 0 ? sortBy : "job";
	return await axios.get(`${urlJob}/getAllInternalJobOffers`, {
		params: {
			page,
			recordsPerPage,
			sortBy,
			sortDirection,
			searchTerm,
			...JobFilter,
		},
	});
}

export async function getAllExternalJobOffers(
	page: number,
	recordsPerPage: number,
	sortBy: string,
	sortDirection: string,
	searchTerm: string,
	descriptionLanguage: string
) {
	sortBy = sortBy.length > 0 ? sortBy : "title";
	return await axios.get(`${urlJob}/getExternalJobOffers`, {
		params: {
			page,
			recordsPerPage,
			sortBy,
			sortDirection,
			searchTerm,
			descriptionLanguage,
		},
	});
}

export async function getJobOfferAPI(jobOfferId: string) {
	return await axios.get<extendedJobOfferDTO>(
		`${urlJob}/jobOffer/${jobOfferId}`
	);
}

export async function getCompanyJobOffersAPI(
	page: number,
	recordsPerPage: number,
	sortBy: string,
	sortDirection: string
) {
	sortBy = sortBy.length > 0 ? sortBy : "jobname";
	return await axios.get(`${urlJob}/getCompanyApplications`, {
		params: { page, recordsPerPage, sortBy, sortDirection },
	});
}

export async function getEmployeeFromUserIdAPI(userId: string) {
	return await axios.get(`${urlRecrewter}/employee/${userId}`);
}

export async function employeeSearchFilterAPI(
	filterSettings: employeeSearchFilterDTO,
	isTopMatch: boolean,
	matchThreshold?: number
) {
	if (matchThreshold != null)
		return await axios.post(
			`${urlRecrewter}/search/${isTopMatch}/${matchThreshold}`,
			filterSettings
		);
	return await axios.post(
		`${urlRecrewter}/search/${isTopMatch}`,
		filterSettings
	);
}

export async function getCountriesAPI() {
	return await axios.get<employeeSearchFilterCountryDTO>(
		`${urlRecrewter}/getCountries`
	);
}

export async function sortTableAPI(
	sortBy: string,
	sortDirection: string,
	location: string
) {
	return await axios.get<sortTableDTO>(`${location}`, {
		params: { sortBy, sortDirection },
	});
}

export async function getChatsAPI() {
	let response = await axios.get<chatDTO[]>(`${urlChat}/all`);
	response.data.forEach((chat) => {
		chat.messages.forEach((message) => {
			message.date = new Date(message.date);
		});
	});

	return response;
}

export async function getUnreadMessageCountAPI() {
	return await axios.get<number>(`${urlChat}/message/unread`);
}

export async function sendMessageAPI(message: messageDTO, chatId: string) {
	return await axios.post(`${urlChat}/message/${chatId}`, message);
}

export async function getNewChatAPI(userId: string) {
	return await axios.get<chatDTO>(`${urlChat}/new/${userId}`);
}

export async function markChatAsReadAPI(chatId: string) {
	return await axios.post(`${urlChat}/read/${chatId}`);
}

export async function removeLastMessageAPI(chatId: string) {
	return await axios.delete(`${urlChat}/message/${chatId}`);
}

export async function getRecrewtersForAdminAPI() {
	return await axios.get<recrewterDetailsForAdminDTO[]>(
		`${urlAccounts}/admin/recrewters`
	);
}

export async function getEmployeesForAdminAPI() {
	return await axios.get<userDetailsForAdminDTO[]>(
		`${urlAccounts}/admin/employees`
	);
}

export async function getCompaniesForAdminAPI() {
	return await axios.get<companyDetailsDTO[]>(`${urlAccounts}/admin/companies`);
}

export async function requestNewUserConnectionAPI(userId: string) {
	return await axios.post(`${urlAccounts}/connection/request/${userId}`);
}

export async function respondUserConnectionAPI(
	userId: string,
	status: Types.ConnectionStatus
) {
	return await axios.post(`${urlAccounts}/connection/${userId}/${status}`);
}

export async function getAllUserConnectionsAPI() {
	return await axios.get<userConnectionDTO[]>(`${urlAccounts}/connections`);
}

export async function activateUserAPI(userId: string, status: boolean) {
	return await axios.post(
		`${urlAccounts}/admin/activate/${status}/user/${userId}`
	);
}

export async function handleSSOLoginAPI(provider: string) {
	return await axios.get(`${urlAccounts}/login/${provider}`);
}

export async function changePasswordAPI(passwords: passwordDTO) {
	return await axios.put(`${urlAccounts}/password/change`, passwords);
}

export async function setNewPasswordAPI(passwords: passwordResetDTO) {
	return await axios.put(`${urlAccounts}/password/reset`, passwords);
}

export async function requestPasswordResetAPI(email: string) {
	return await axios.put(
		`${urlAccounts}/password/request`,
		JSON.stringify(email),
		{ headers: { "Content-Type": "application/json" } }
	);
}

import { Button, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import i18n from "../../translations/i18n";
import { resendEmailVerificationAPI } from "../../core/apiFunctions";

const VerifyEmail = () => {
	const { t } = useTranslation();
	const navigation = useNavigate();
	const location = useLocation();
	const [email, setEmail] = useState(location.state?.email || "");
	const [isTokenValid, setIsTokenValid] = useState(
		location.state?.isTokenValid ?? true
	);
	const [errorMessage, setErrorMessage] = useState("");
	const [resendEmailSent, setResendEmailSent] = useState(
		location.state?.resendEmailSent || false
	);

	const resendEmailVerification = async () => {
		if (email && email.trim() !== "") {
			try {
				const response = await resendEmailVerificationAPI(email);
				if (response.status === 200) {
					setIsTokenValid(true);
					setResendEmailSent(true);

					navigation("/verifyEmail", {
						state: {
							email,
							isTokenValid: true,
							resendEmailSent: true,
						},
					});
				} else {
					throw new Error();
				}
			} catch (error: any) {
				if (error.response && error.response.data) {
					setIsTokenValid(false);
					setErrorMessage("error.resendEmailVerification");
				}
			}
		}
	};

	useEffect(() => {
		i18n.changeLanguage(navigator.language.includes("de") ? "de" : "en");

		if (window.location.href.includes("/invalid") && !resendEmailSent) {
			setIsTokenValid(false);

			// get email from url
			const url = new URL(window.location.href);
			const email = url.pathname.split("/").pop();

			if (email && email.includes("@")) {
				setEmail(email);
			}
		} else {
			setIsTokenValid(true);
		}
	}, [resendEmailSent]);

	return (
		<>
			<Helmet>
				<title>Verify Email | reCREWter</title>
			</Helmet>

			{/* login wrapper start */}
			<div className="auth-wrapper login verifyEmail-wrapper">
				<Container maxWidth="lg">
					{/* inner start */}
					<div className="inner">
						{/* left start */}
						<div className="left">
							<div className="content">
								<img
									src={images.Ellipse1}
									className="ellipse-one"
									alt="Blur Background"
								/>
								<img
									src={images.Ellipse2}
									className="ellipse-two"
									alt="Blur Background"
								/>
								<img src={images.Logo} className="logo" alt="reCREWter" />
								<h3>{t("emailNotVerifiedTitle")}</h3>
							</div>
						</div>
						{/* left end */}

						{/* right start */}
						<div
							className="right"
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							{isTokenValid ? (
								<>
									<span
										style={{
											lineHeight: "35px",
											fontSize: "20px",
										}}
									>
										{t("emailNotVerified")}
									</span>
									<Button
										variant="contained"
										title="Login"
										onClick={() => navigation("/login")}
									>
										{t("login")}
									</Button>
									<div className="separator">
										<div />
										<span>{t("or")}</span>
										<div />
									</div>

									{/* Show either the resend button or success message */}
									{resendEmailSent ? (
										<div
											style={{
												display: "flex",
												alignItems: "center",
												gap: "8px",
												marginTop: "10px",
											}}
										>
											<img
												src={images.CheckCircle}
												alt="Success"
												style={{
													width: "24px",
													height: "24px",
													color: "#75a932",
													filter:
														"invert(39%) sepia(43%) saturate(374%) hue-rotate(60deg) brightness(91%) contrast(90%)",
												}}
											/>
											<span
												style={{
													color: "#75a932",
													fontSize: "18px",
													fontWeight: "bold",
												}}
											>
												{t("emailSentSuccessfully")}
											</span>
										</div>
									) : (
										<Button
											variant="contained"
											title="Send Again"
											onClick={resendEmailVerification}
										>
											{t("btnResend")}
										</Button>
									)}
								</>
							) : (
								<>
									<span
										style={{
											lineHeight: "35px",
											fontSize: "20px",
										}}
									>
										{errorMessage
											? t(errorMessage)
											: t("invalidTokenResendEmail")}
									</span>
									{!errorMessage ? (
										<Button
											variant="contained"
											title="Send Again"
											onClick={resendEmailVerification}
										>
											{t("btnSend")}
										</Button>
									) : (
										<Button
											variant="contained"
											title="Login"
											onClick={() => navigation("/login")}
										>
											{t("login")}
										</Button>
									)}
								</>
							)}
						</div>
						{/* right end */}
					</div>
					{/* inner end */}
				</Container>
			</div>
			{/* login wrapper end */}
		</>
	);
};

export default VerifyEmail;

import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import { useEffect, useState } from "react";
import { userDetailsDTO } from "./core/dto/user.models";
import { getUserDetailsAPI } from "./core/apiFunctions";
import configureInterceptors from "./utils/httpInterceptors";
import AuthenticationContext from "./components/auth/AuthenticationContext";
import { claim } from "./core/dto/auth.models";
import { getClaims } from "./core/handleJWT";
import FeedbackToast from "./utils/FeedbackToast";
import theme from "./App.theme";
import Register from "./pages/Register/Register";
import { checkLoggedInStatus } from "./core/claimFunctions";

import Login from "./pages/Login/Login";
import VerifyEmail from "./pages/verifyEmail/verifyEmail";
import { ImageProvider } from "./contexts/ImageContext";
import ConfirmEmail from "./pages/confirmEmail/confirmEmail";
import { Sso } from "./pages/Sso/Sso";
import MyProfile from "./pages/MyProfile/MyProfile";
import MyCv from "./pages/MyCV/MyCV";
import { UserRoles } from "./config/Types/GeneralEnumDefinitions";
import Jobs from "./pages/jobs/Jobs";
import AuthorizedExtended from "./components/auth/AuthorizedExtended";
import RedirectToHome from "./utils/RedirectToHome";
import Chat from "./pages/chat/Chat";
import MyCompany from "./pages/MyCompany/MyCompany";
import Loader from "./utils/Loader";
import { LoadingProvider } from "./contexts/LoadingContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { Personal } from "./pages/personal/Personal";
import { AddEditJobs } from "./pages/addEditJobs/AddEditJobs";

declare global {
	interface Window {
		umami: any;
	}
}
configureInterceptors();
function App() {
	const [userAccDetails, setUserAccDetails] = useState<userDetailsDTO>();
	const [claims, setClaims] = useState<claim[]>([]);
	const [success, setSuccess] = useState(false);
	const [message, setMessage] = useState("");
	const [mode, setMode] = useState(0);
	const [duration, setDuration] = useState(0);

	const update = (newClaims: claim[]) => {
		setClaims(newClaims);
	};

	useEffect(() => {
		const claims = getClaims();
		if (checkLoggedInStatus(claims)) {
			fetchInitDetails();
			setClaims(getClaims());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchInitDetails = () => {
		getUserDetailsAPI().then((response) => {
			setUserAccDetails(response.data);
		});
	};

	return (
		<LoadingProvider>
			<div className="App">
				<Loader />
				<AuthenticationContext.Provider
					value={{
						userAccDetails,
						claims,
						update,
						success,
						setSuccess,
						message,
						setMessage,
						mode,
						setMode,
						duration,
						setDuration,
					}}
				>
					<FeedbackToast />

					<ThemeProvider theme={theme}>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Router>
								<ImageProvider>
									<WebSocketProvider>
										<Routes>
											<Route path="/" element={<Login />} />
											<Route path="/auth" element={<Login />} />
											<Route path="/login" element={<Login />} />
											<Route path="/register" element={<Register />} />
											<Route path="/sso" element={<Sso />} />

											<Route path="/confirmEmail" element={<ConfirmEmail />} />
											<Route path="/verifyEmail" element={<VerifyEmail />} />
											<Route
												path="/verifyEmail/invalid/:email"
												element={<VerifyEmail />}
											/>

											<Route
												path="/jobs"
												element={
													<AuthorizedExtended
														authorized={<Jobs />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[
															UserRoles.Employee,
															UserRoles.Recrewter,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/jobs/:jobofferId"
												element={
													<AuthorizedExtended
														authorized={<AddEditJobs />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[UserRoles.Recrewter, UserRoles.Admin]}
													/>
												}
											/>
											<Route
												path="/profile"
												element={
													<AuthorizedExtended
														authorized={<MyProfile />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[
															UserRoles.Employee,
															UserRoles.Recrewter,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/cv"
												element={
													<AuthorizedExtended
														authorized={<MyCv />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[
															UserRoles.Employee,
															UserRoles.Recrewter,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/staff/cv/:userId"
												element={
													<AuthorizedExtended
														authorized={<MyCv />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[UserRoles.Recrewter, UserRoles.Admin]}
													/>
												}
											/>
											<Route
												path="company/cv/:userId"
												element={
													<AuthorizedExtended
														authorized={<MyCompany />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[
															UserRoles.Recrewter,
															UserRoles.Employee,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/company"
												element={
													<AuthorizedExtended
														authorized={<MyCompany />}
														notAuthorized={<MyCompany />}
														relogIn={<Login />}
														roles={[
															UserRoles.Employee,
															UserRoles.Recrewter,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/chat/:participantId/:contentForMessage"
												element={
													<AuthorizedExtended
														authorized={<Chat />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[
															UserRoles.Employee,
															UserRoles.Recrewter,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/chat/:participantId"
												element={
													<AuthorizedExtended
														authorized={<Chat />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[
															UserRoles.Employee,
															UserRoles.Recrewter,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/chat"
												element={
													<AuthorizedExtended
														authorized={<Chat />}
														notAuthorized={<MyProfile />}
														relogIn={<Login />}
														roles={[
															UserRoles.Employee,
															UserRoles.Recrewter,
															UserRoles.Admin,
														]}
													/>
												}
											/>

											<Route
												path="/dashboard"
												element={
													<AuthorizedExtended
														authorized={<Dashboard />}
														notAuthorized={<MyProfile />} // TODO: check which component to render
														relogIn={<Login />}
														roles={[
															UserRoles.Recrewter,
															UserRoles.Employee,
															UserRoles.Admin,
														]}
													/>
												}
											/>
											<Route
												path="/staff"
												element={
													<AuthorizedExtended
														authorized={<Personal />}
														notAuthorized={<MyProfile />} // TODO: check which component to render
														relogIn={<Login />}
														roles={[UserRoles.Recrewter, UserRoles.Admin]}
													/>
												}
											/>
											<Route path="*" element={<RedirectToHome />} />
										</Routes>
									</WebSocketProvider>
								</ImageProvider>
							</Router>
						</LocalizationProvider>
					</ThemeProvider>
				</AuthenticationContext.Provider>
			</div>
		</LoadingProvider>
	);
}

export default App;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import Pagination from "@mui/material/Pagination/Pagination";
import { AxiosResponse } from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";
import JobDetailsDialog from "../../components/JobDetailsDialog/JobDetailsDialog";
import Sidebar from "../../components/Sidebar/Sidebar";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import Header from "../../components/header/Header";
import {
	Availability,
	EducationLevel,
	EmploymentType,
	FeedbackMode,
	JobPosition,
	Salary,
	Title,
	UserRoles,
	Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import { useLoading } from "../../contexts/LoadingContext";
import {
	deleteJobOfferAPI,
	getAllExternalJobOffers,
	getAllInternalJobOffers,
	getCompanyJobOffersAPI,
	getDescriptionLanguages,
	toggleJobOfferAPI,
} from "../../core/apiFunctions";
import {
	checkRole,
	getEmail,
	getName,
	getNameIdentifier,
	getRoleId,
} from "../../core/claimFunctions";
import {
	extendedJobOfferDTO,
	externalJobOfferDTO,
	jobApplicationDTO,
	jobFilterDto,
} from "../../core/dto/dto.models";
import { useDebounce } from "../../core/helperFunctions";
import useFeedback from "../../utils/useFeedback";
import { InternalJobCard } from "./InternalJobCard";
import { useNavigate } from "react-router-dom";
import DropdownField from "../../components/common/DropdownField";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import {
	handleOnChange,
	listOfCountriesWithStates,
	listOfDivisionWithJobType,
} from "../../core/helperConst";
import {
	JobType,
	MedicalDivision,
} from "../../config/Types/MedicalEnumDefinitions";
import RichTextEditor from "../../utils/RichTextEditor";

const DefaultJobFilters: jobFilterDto = {
	country: Country.None,
	state: State.None,
	employmentType: EmploymentType.None,
	jobPosition: JobPosition.None,
	jobType: JobType.None,
	workload: Workload.None,
	salary: Salary.None,
	division: MedicalDivision.None,
	educationLevel: EducationLevel.None,
	availability: Availability.None,
};

const Jobs = () => {
	const { setLoading } = useLoading();
	const [bookmarkJobs, setBookmarkJobs] = useState<string[]>([]);
	const [jobOfferModal, setJobOfferModal] = useState<
		| {
				jobType: "Internal";
				jobData: extendedJobOfferDTO;
		  }
		| {
				jobType: "External";
				jobData: externalJobOfferDTO;
		  }
		| {
				jobType: "Application";
				jobData: jobApplicationDTO;
		  }
	>();
	const [filterSettings, setFilterSettings] =
		useState<jobFilterDto>(DefaultJobFilters);
	const [showFilter, setShowFilters] = useState<boolean>(false);

	const [open, setOpen] = useState(false);

	const handleDialogClose = () => {
		setOpen(false);
	};

	const handleDialogOpen = (
		data:
			| {
					jobType: "Internal";
					jobData: extendedJobOfferDTO;
			  }
			| {
					jobType: "External";
					jobData: externalJobOfferDTO;
			  }
			| {
					jobType: "Application";
					jobData: jobApplicationDTO;
			  }
	) => {
		setJobOfferModal(data);
		setOpen(true);
	};

	// Jobs Logic

	const { t } = useTranslation();
	const { claims } = useContext(AuthenticationContext);
	const { showSuccess, showError } = useFeedback();
	const [jobOfferEntities, setJobOfferEntities] = useState<
		extendedJobOfferDTO[]
	>([]);
	const [externalJobOfferEntities, setExternalJobOfferEntities] = useState<
		externalJobOfferDTO[]
	>([]);
	const [applicationEntities, setApplicationEntities] = useState<
		jobApplicationDTO[]
	>([]);

	const [totalAmountOfInternalPages, setTotalAmountOfInternalPages] =
		useState(0);
	const [internalRecordsPerPage, setInternalRecordsPerPage] = useState(25);
	const [internalPage, setInternalPage] = useState(1);

	const [totalAmountOfExternalPages, setTotalAmountOfExternalPages] =
		useState(0);
	const [externalRecordsPerPage, setExternalRecordsPerPage] = useState(25);
	const [externalPage, setExternalPage] = useState(1);

	const [totalAmountOfCompanyPages, setTotalAmountOfCompanyPages] = useState(0);
	const [companyRecordsPerPage, setCompanyRecordsPerPage] = useState(25);
	const [companyPage, setCompanyPage] = useState(1);
	const [currentRole] = useState(getRoleId(claims));
	const [searchTerm, setSearchTerm] = useState("");
	const [sortByInternal, setSortByInternal] = useState("jobname");
	const [sortByExternal, setSortByExternal] = useState("title");
	const [sortByCompany, setSortByCompany] = useState("name");
	const [sortDirection, setSortDirection] = useState("ASC");

	const [descriptionLanguage, setDescriptionLanguage] = useState<string>("");
	const [descriptionLanguages, setDescriptionLanguages] = useState<string[]>(
		[]
	);

	const debouncedDescriptionLanguage = useDebounce(descriptionLanguage, 500);
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	const navigate = useNavigate();

	useEffect(() => {
		if (UserRoles.Employee === currentRole) {
			loadInternalJobOffers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		internalPage,
		internalRecordsPerPage,
		sortByInternal,
		sortDirection,
		debouncedSearchTerm,
	]);

	useEffect(() => {
		if (UserRoles.Employee === currentRole) {
			loadExternalJobOffers();
			loadDescriptionLanguages();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		externalPage,
		externalRecordsPerPage,
		sortByExternal,
		sortDirection,
		debouncedDescriptionLanguage,
		debouncedSearchTerm,
	]);

	useEffect(() => {
		if (UserRoles.Recrewter === currentRole) {
			loadCompanyJobOffers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyPage, companyRecordsPerPage, sortByCompany, sortDirection]);

	const onChange = handleOnChange(filterSettings, setFilterSettings);

	function loadInternalJobOffers(
		filters = filterSettings,
		Page = internalPage
	) {
		setLoading(true);
		getAllInternalJobOffers(
			Page,
			internalRecordsPerPage,
			sortByInternal,
			sortDirection,
			debouncedSearchTerm,
			filters
		)
			.then((response: AxiosResponse<extendedJobOfferDTO[]>) => {
				const totalAmountOfRecords = parseInt(
					response.headers["totalamountofrecords"],
					10
				);
				setTotalAmountOfInternalPages(
					Math.ceil(totalAmountOfRecords / internalRecordsPerPage)
				);

				var offers: extendedJobOfferDTO[] = [];
				response.data.forEach((jobOffer) => {
					const name = getName(claims);
					if (jobOffer.salutation === Title.None) {
						jobOffer.applyUrl =
							"/chat/" +
							jobOffer.applyUrl +
							"/" +
							encodeURIComponent(
								t("message.hello.general") +
									t("message.intentionApply") +
									'"' +
									jobOffer.title +
									'" ' +
									t("message.at") +
									" " +
									jobOffer.companyName +
									"."
							);
					} else {
						jobOffer.applyUrl =
							"/chat/" +
							jobOffer.applyUrl +
							"/" +
							encodeURIComponent(
								t("message.hello") +
									Title[jobOffer.salutation ?? Title.None] +
									" " +
									name +
									". " +
									t("message.intentionApply") +
									'"' +
									jobOffer.title +
									'" ' +
									t("message.at") +
									" " +
									jobOffer.companyName +
									"."
							);
					}
					offers.push(jobOffer);
				});

				setJobOfferEntities(offers);
			})
			.finally(() => setLoading(false));
	}

	function loadExternalJobOffers(page = externalPage) {
		setLoading(true);
		getAllExternalJobOffers(
			page,
			externalRecordsPerPage,
			sortByExternal,
			sortDirection,
			debouncedSearchTerm,
			debouncedDescriptionLanguage
		)
			.then((response: AxiosResponse<externalJobOfferDTO[]>) => {
				const totalAmountOfRecords = parseInt(
					response.headers["totalamountofrecords"],
					10
				);
				setTotalAmountOfExternalPages(
					Math.ceil(totalAmountOfRecords / externalRecordsPerPage)
				);

				setExternalJobOfferEntities(response.data);
			})
			.finally(() => setLoading(false));
	}

	function loadCompanyJobOffers() {
		setLoading(true);
		getCompanyJobOffersAPI(
			companyPage,
			companyRecordsPerPage,
			sortByCompany,
			sortDirection
		)
			.then((response: AxiosResponse<jobApplicationDTO[]>) => {
				const totalAmountOfRecords = parseInt(
					response.headers["totalamountofrecords"],
					10
				);
				setTotalAmountOfCompanyPages(
					Math.ceil(totalAmountOfRecords / companyRecordsPerPage)
				);

				setApplicationEntities(response.data);
			})
			.finally(() => setLoading(false));
	}

	function loadDescriptionLanguages() {
		getDescriptionLanguages().then((response: AxiosResponse<string[]>) => {
			setDescriptionLanguages(response.data);
		});
	}

	const handleExternalPageChange = (
		event: React.ChangeEvent<unknown>,
		page: number
	) => {
		setExternalPage(page);
	};

	const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	const handleSearch = () => {
		if (checkRole(claims, UserRoles.Employee)) {
			setExternalPage(1);
			setInternalPage(1);
			loadInternalJobOffers();
			loadExternalJobOffers();
		} else {
			setCompanyPage(1);
			loadCompanyJobOffers();
		}
	};

	const handleResetFilter = () => {
		setFilterSettings(DefaultJobFilters);
		setExternalPage(1);
		setInternalPage(1);
		loadInternalJobOffers(DefaultJobFilters, 1);
		loadExternalJobOffers(1);
	};

	const handleDescriptionLanguageChange = (e: SelectChangeEvent<string>) => {
		setDescriptionLanguage(e.target.value);
		setExternalPage(1);
	};

	const handleExternalUrlClick = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		job: externalJobOfferDTO
	) => {
		e.stopPropagation();
		if (window.umami) {
			var userId = getNameIdentifier(claims);
			var name = getName(claims);
			var email = getEmail(claims);
			window.umami.track(job.title + " clicked", {
				jobId: job.id,
				url: job.url,
				userId: userId,
				email: email,
				name: name,
			});
		}
	};

	const handleBookmarkClick = (id: string) => {
		setBookmarkJobs((prev) =>
			prev.indexOf(id) !== -1 ? prev.filter((pId) => id !== pId) : [...prev, id]
		);
	};

	const getCompanyLogo = (clientName: string) => {
		if (clientName.toLowerCase().includes("jobs")) {
			return images.JobChLogo;
		} else if (clientName.toLowerCase().includes("jobup")) {
			return images.JobupLogo;
		} else if (clientName.toLowerCase().includes("alpha")) {
			return images.AlphaLogo;
		} else if (clientName.toLowerCase().includes("ictcareer")) {
			return images.IctcareerChLogo;
		} else if (clientName.toLowerCase().includes("ingjobs")) {
			return images.IngjobsChLogo;
		} else if (clientName.toLowerCase().includes("jobs4finance")) {
			return images.Jobs4financeChLogo;
		} else if (clientName.toLowerCase().includes("jobs4sales")) {
			return images.Jobs4salesChLogo;
		} else if (clientName.toLowerCase().includes("medtalents")) {
			return images.MedtalentsChLogo;
		} else if (clientName.toLowerCase().includes("topjobs")) {
			return images.TopjobsChLogo;
		} else if (clientName.toLowerCase().includes("jobscout24")) {
			return images.Jobscout24Logo;
		} else if (clientName.toLowerCase().includes("jobcloud")) {
			return images.JobcloudLogo;
		} else if (clientName.toLowerCase().includes("jobwinner")) {
			return images.JobWinnerLogo;
		} else return images.JobChLogo;
	};

	const handleToggleApplicationHidden = (jobUrl: string | undefined) => {
		setLoading(true);
		toggleJobOfferAPI(jobUrl ?? "")
			.then(() => {
				const updatedValues = applicationEntities.map((item) =>
					item.url === jobUrl
						? { ...item, isPublished: !item.isPublished }
						: item
				);
				setApplicationEntities(updatedValues);
			})
			.finally(() => setLoading(false));
	};

	const handleApplicationDelete = (url: string) => {
		setLoading(true);
		try {
			deleteJobOfferAPI(url).finally(() => setLoading(false));
			const updatedJobOffers = applicationEntities.filter(
				(offer) => offer.url !== url
			);
			setApplicationEntities(updatedJobOffers);
			showSuccess(t("jobOfferDeletedSuccessfully"), FeedbackMode.Success, 5000);
		} catch (error: any) {
			if (error.response) {
				if (error.response.status === 401 || error.response.status === 404) {
					showError(t(error.response.data), FeedbackMode.Error, 5000);
				} else {
					showError(t("errorOccuredWhileDeleting"));
				}
			} else {
				showError(t("errorOccuredWhileDeleting"));
			}
		}
	};
	return (
		<>
			<Helmet>
				<title>
					{t("jobs")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* inner-wrapper start */}
					<div className="inner-wrapper">
						{checkRole(claims, UserRoles.Employee) && (
							<>
								{/* job search start */}
								<div className="job-search">
									<FormControl>
										<OutlinedInput
											placeholder={t("searchJob")}
											value={searchTerm}
											onChange={handleSearchTermChange}
											startAdornment={
												<InputAdornment position="start">
													<img src={images.SearchLarge} alt="Search" />
												</InputAdornment>
											}
										/>
									</FormControl>
									<Button
										variant="outlined"
										title={t("menuSearch")}
										onClick={handleSearch}
										className="jobs-search-button"
									>
										<img src={images.Search} alt="Search" />
										<span>{t("menuSearch")}</span>
									</Button>
									<Button
										variant="contained"
										title={t("filter")}
										onClick={() => setShowFilters(!showFilter)}
									>
										<img src={images.Filter} alt="Filter" />
										<span>{t("filter")}</span>
									</Button>
								</div>

								{showFilter && (
									<div className="job-search-dropdowns">
										<Grid container spacing={{ xs: 3, sm: 4, lg: 5 }}>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerCountry"}
													field={"country"}
													selected={filterSettings?.country}
													onChange={onChange}
													enumType={Country}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={
														filterSettings?.country === Country.Switzerland
															? "headerCanton"
															: "headerState"
													}
													field={"state"}
													index={
														filterSettings?.country === Country.Germany
															? 8400
															: filterSettings?.country === Country.Austria
																? 1500
																: 21600 // Default for Switzerland
													}
													indexSize={
														filterSettings?.country === Country.Germany
															? 17
															: filterSettings?.country === Country.Austria
																? 10
																: 27 // Default for Switzerland (Canton)
													}
													selected={
														!listOfCountriesWithStates.includes(
															filterSettings?.country
														)
															? State.None
															: filterSettings?.state!
													}
													disabled={
														filterSettings?.country === Country.None ||
														!listOfCountriesWithStates.includes(
															filterSettings?.country!
														)
													}
													onChange={onChange}
													enumType={State}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerEmployementType"}
													field={"employmentType"}
													selected={filterSettings?.employmentType}
													onChange={onChange}
													enumType={EmploymentType}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerPosition"}
													field={"jobPosition"}
													selected={filterSettings?.jobPosition}
													onChange={onChange}
													enumType={JobPosition}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerPensum"}
													field={"workload"}
													selected={filterSettings?.workload}
													onChange={onChange}
													enumType={Workload}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerSalary"}
													field={"salary"}
													selected={filterSettings?.salary}
													onChange={onChange}
													enumType={Salary}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerDivision"}
													field={"division"}
													selected={filterSettings?.division}
													onChange={(e) =>
														setFilterSettings((prev) => ({
															...prev,
															division: e.target.value,
															jobType: 0,
														}))
													}
													enumType={MedicalDivision}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerJobType"}
													field={"jobType"}
													index={
														filterSettings?.division ===
														MedicalDivision.Pharmacist
															? 200
															: filterSettings?.division ===
																  MedicalDivision.Physician
																? 300
																: filterSettings?.division ===
																	  MedicalDivision.MedicalAssistant
																	? 2800
																	: 0 // Default for Switzerland
													}
													indexSize={
														filterSettings?.division ===
														MedicalDivision.Pharmacist
															? 7
															: filterSettings?.division ===
																  MedicalDivision.Physician
																? 53
																: filterSettings?.division ===
																	  MedicalDivision.MedicalAssistant
																	? 23
																	: 0 // Default for Switzerland (Canton)
													}
													selected={
														!listOfDivisionWithJobType.includes(
															filterSettings?.division
														)
															? State.None
															: filterSettings?.jobType!
													}
													disabled={
														filterSettings?.division === MedicalDivision.None ||
														!listOfDivisionWithJobType.includes(
															filterSettings?.division!
														)
													}
													onChange={onChange}
													enumType={JobType}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerEducationLevel"}
													field={"educationLevel"}
													selected={filterSettings?.educationLevel}
													onChange={onChange}
													enumType={EducationLevel}
												/>
											</Grid>
											<Grid size={{ sm: 6, md: 4, xs: 12 }}>
												<DropdownField
													displayName={"headerAvailability"}
													field={"availability"}
													selected={filterSettings?.availability}
													onChange={onChange}
													enumType={Availability}
												/>
											</Grid>

											<Grid size={{ md: 8, xs: 12 }} className="btn-group">
												<Button variant="outlined" onClick={handleResetFilter}>
													{t("reset")}
												</Button>
												<Button variant="contained" onClick={handleSearch}>
													{t("menuSearch")}
												</Button>
											</Grid>
										</Grid>
									</div>
								)}

								{/* job search end */}
							</>
						)}

						{/* job listing start */}
						<div className="job-listing">
							{checkRole(claims, UserRoles.Recrewter) ? (
								<>
									<Grid container alignItems={"center"} marginBlock={"12px"}>
										<Grid
											size={{ xs: 12, sm: 6 }}
											justifyContent={"space-between"}
										>
											<Typography
												variant="h4"
												style={{
													padding: "10px",
													color: "#75a932",
												}}
											>
												{t("myListedJobOffers")}
											</Typography>
										</Grid>
										<Grid
											container
											size={{ xs: 12, sm: 6 }}
											justifyContent={"end"}
										>
											<Button
												variant="contained"
												color="primary"
												onClick={() => navigate("create")}
												startIcon={<img src={images.Plus} alt="create job" />}
												className="createJobOfferButton"
											>
												{t("button.createJobOffer")}
											</Button>
										</Grid>
									</Grid>
									<ul>
										{applicationEntities.map((jobs, index) => {
											return (
												<InternalJobCard
													key={index}
													jobs={jobs}
													jobType="Application"
													onClickJob={handleDialogOpen}
													handleToggleApplicationHidden={
														handleToggleApplicationHidden
													}
													handleApplicationDelete={handleApplicationDelete}
													bookmarkClick={handleBookmarkClick}
													isBookmark={
														bookmarkJobs.indexOf(jobs.id || "") !== -1
													}
												/>
											);
										})}
										{applicationEntities.length === 0 && (
											<div>
												<Typography
													variant="body1"
													align="center"
													style={{
														padding: "30px",
														minHeight: "100px",
														borderRadius: "16px",
														border: "0.5px solid rgb(117, 169, 50)",
													}}
												>
													{t("noData")}
												</Typography>
											</div>
										)}

										{totalAmountOfCompanyPages > 0 && (
											<Pagination
												page={internalPage}
												count={totalAmountOfCompanyPages}
												color="primary"
												sx={{
													borderRadius: "15px",
													marginBottom: "30px",
												}}
											/>
										)}
									</ul>
								</>
							) : (
								<>
									<Typography
										variant="h4"
										align="center"
										style={{
											marginTop: "10px",
											marginBottom: "10px",
											padding: "10px",
											color: "#75a932",
										}}
									>
										Digital reCREWter {t("jobOffers")}
									</Typography>

									<ul>
										{jobOfferEntities.map((jobs) => {
											return (
												<InternalJobCard
													key={jobs.id}
													jobs={jobs}
													jobType="Internal"
													onClickJob={handleDialogOpen}
													bookmarkClick={handleBookmarkClick}
													isBookmark={
														bookmarkJobs.indexOf(jobs.id || "") !== -1
													}
												/>
											);
										})}

										{jobOfferEntities.length === 0 && (
											<div>
												<Typography
													variant="body1"
													align="center"
													style={{
														padding: "30px",
														minHeight: "100px",
														borderRadius: "16px",
														border: "0.5px solid rgb(117, 169, 50)",
													}}
												>
													{t("noData")}
												</Typography>
											</div>
										)}

										{totalAmountOfInternalPages > 0 && (
											<Pagination
												page={internalPage}
												count={totalAmountOfInternalPages}
												color="primary"
												sx={{
													borderRadius: "15px",
													marginBottom: "30px",
												}}
											/>
										)}

										<Typography
											variant="h4"
											align="center"
											style={{
												marginTop: "10px",
												marginBottom: "10px",
												padding: "10px",
												color: "#75a932",
											}}
										>
											{t("externalJobOffer")}
										</Typography>

										{externalJobOfferEntities.length > 0 &&
											descriptionLanguages.length > 0 && (
												<div
													style={{
														paddingBottom: "20px",
													}}
												>
													<FormControl
														className="select"
														sx={{ width: "280px" }}
													>
														<label
															htmlFor="descriptionLanguage"
															style={{ color: "#969ba0" }}
														>
															{t("descriptionLanguage")}
														</label>
														<Select
															value={descriptionLanguage}
															placeholder="Please Select"
															id="descriptionLanguage"
															displayEmpty
															onChange={handleDescriptionLanguageChange}
															key={`descriptionLanguage-${descriptionLanguage}`}
														>
															<MenuItem value={""}>{t("all")}</MenuItem>
															{descriptionLanguages.map((language) => (
																<MenuItem value={language} key={language}>
																	{t(language)}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</div>
											)}

										{externalJobOfferEntities.length > 0 &&
											externalJobOfferEntities.map((externalJob) => (
												<li key={externalJob.id}>
													<div
														className="card"
														onClick={() =>
															handleDialogOpen({
																jobType: "External",
																jobData: externalJob,
															})
														}
													>
														<div className="job-title">
															<div className="left">
																<img
																	className="clientLogo"
																	src={getCompanyLogo(externalJob.clientName)}
																	alt="Company Logo"
																/>
																<div className="content">
																	<p>{externalJob.title}</p>
																	<ul>
																		<li>{externalJob.company}</li>
																		<li>
																			{externalJob.city}
																			{externalJob.postalCode
																				? `, ${externalJob.postalCode}`
																				: ""}
																		</li>
																	</ul>
																</div>
															</div>
															{/* <IconButton
														className={
															bookmarkJobs.indexOf(externalJob.id) !== -1
																? "active"
																: ""
														}
														onClick={(e) => {
															e.stopPropagation();
															handleBookmarkClick(externalJob.id);
														}}
													>
														<img
															src={
																bookmarkJobs.indexOf(externalJob.id) !== -1
																	? images.BookmarkActive
																	: images.Bookmark
															}
															alt="Bookmark"
															title="Bookmark"
														/>
													</IconButton> */}
														</div>
														<RichTextEditor
															value={externalJob.description}
															readOnly={true}
															className="job-description"
														/>

														<Grid container spacing={0} className="job-details">
															<Grid size={{ xs: 12 }}>
																<small>{t("employmentGradeMinMax")}</small>
																<span>{`${
																	externalJob.employmentGradeMax ===
																	externalJob.employmentGradeMin
																		? externalJob.employmentGradeMax + "%"
																		: `${externalJob.employmentGradeMin}% - ${externalJob.employmentGradeMax}%`
																} `}</span>
															</Grid>
														</Grid>
														<Link
															href={externalJob.url}
															target="_blank"
															title={t("checkOutJobsCH", {
																clientName: externalJob.clientName,
															})}
															onClick={(e) =>
																handleExternalUrlClick(e, externalJob)
															}
														>
															<img src={images.Globe} alt="Globe" />
															<span>
																{t("checkOutJobsCH", {
																	clientName: externalJob.clientName,
																})}
															</span>
														</Link>
													</div>
												</li>
											))}

										{externalJobOfferEntities.length === 0 && (
											<div>
												<Typography
													variant="body1"
													align="center"
													style={{
														padding: "30px",
														minHeight: "100px",
														borderRadius: "16px",
														border: "0.5px solid rgb(117, 169, 50)",
													}}
												>
													{t("noData")}
												</Typography>
											</div>
										)}
									</ul>
									{totalAmountOfExternalPages > 0 && (
										<Pagination
											page={externalPage}
											count={totalAmountOfExternalPages}
											color="primary"
											onChange={handleExternalPageChange}
										/>
									)}
								</>
							)}
						</div>
						{/* job listing end */}
					</div>
					{/* inner-wrapper end */}
				</main>
				{/* main end */}
			</div>
			{/* wrapper end */}
			<JobDetailsDialog
				open={open}
				onClose={handleDialogClose}
				jobDetail={jobOfferModal}
				setJobDetail={setJobOfferModal}
				bookmarkClick={handleBookmarkClick}
				isBookmark={
					bookmarkJobs.indexOf(jobOfferModal?.jobData.id || "") !== -1
				}
			/>
		</>
	);
};

export default Jobs;

import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid2";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AuthenticationContext from "../../components/auth/AuthenticationContext";
import {
	AcademicDegree,
	Availability,
	EducationLevel,
	EmploymentType,
	FieldOfStudy,
	JobPosition,
	LanguageNiveau,
	Salary,
	UserRoles,
	Workload,
	YearsOfExperience,
} from "../../config/Types/GeneralEnumDefinitions";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import { getRoleId } from "../../core/claimFunctions";
import { internalJobDTO } from "../../core/dto/dto.models";
import {
	aboutMeDTO,
	certificateDTO,
	educationDTO,
	idealCandidateDTO,
	languageSkillDTO,
	skillDTO,
	userDetailsDTO,
	workExperienceDTO,
} from "../../core/dto/user.models";
import { enumToString, formatUrlLink } from "../../core/helperConst";
import { Link } from "@mui/material";
import images from "../../assets/images";
type Props = {
	userDetails: userDetailsDTO | undefined;
	dreamJob: internalJobDTO;
	aboutMe: aboutMeDTO | undefined;
	cvVideo: string | undefined;
	languageSkills: languageSkillDTO[];
	skills: skillDTO[];
	idealCandidate: idealCandidateDTO;
	certificates: certificateDTO[];
	educations: educationDTO[];
	workExperiences: workExperienceDTO[];
};
export const ViewProfile = ({
	dreamJob,
	userDetails,
	aboutMe,
	languageSkills,
	skills,
	cvVideo,
	idealCandidate,
	certificates,
	educations,
	workExperiences,
}: Props) => {
	const { claims } = useContext(AuthenticationContext);
	const { t } = useTranslation();

	const [role] = useState<UserRoles>(getRoleId(claims) ?? 0);
	function formatDate(dateString: string) {
		const date = new Date(dateString);
		const options: Intl.DateTimeFormatOptions = {
			year: "numeric",
			month: "long",
			day: "numeric",
		};
		return date.toLocaleDateString("en-US", options);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			{role === UserRoles.Employee && (
				<>
					{/* job description start */}
					<div className="job-description card" id="myDreamJob">
						<h6>{t("myDreamJobDescription")}</h6>
						<p>{dreamJob?.description}</p>
					</div>
					{/* job description end */}

					{/* my cv start */}

					<div className="card my-cv" id="myCv">
						<h6>{t("myCv")}</h6>
						<p>{t("aboutMe")}</p>
						<span>{aboutMe?.aboutMe ? aboutMe.aboutMe : "-"}</span>

						{skills.length > 0 && (
							<>
								<p>{t("skills")}</p>
								<div className="professional-experience view" id="skills">
									<ul>
										{skills.map((skill) => (
											<li key={skill.id}>
												<div className="icon">
													<img src={images.SkillWhite} alt="User" />
												</div>
												<div className="details">
													<p>{skill.name}</p>
													<span>{`${t(enumToString(YearsOfExperience, skill.years) || "")}`}</span>
												</div>
											</li>
										))}
									</ul>
								</div>
							</>
						)}

						{languageSkills.length > 0 && (
							<>
								<p>{t("languageSkills")}</p>
								<div
									className="professional-experience view"
									id="languageSkill"
								>
									<ul>
										{languageSkills.map((skill) => (
											<li key={skill.id}>
												<div className="icon">
													<img src={images.LanguageSkillWhite} alt="User" />
												</div>
												<div className="details">
													<p>{skill.language}</p>
													<span>{`${t(enumToString(LanguageNiveau, skill.niveau) || "")}`}</span>
												</div>
											</li>
										))}
									</ul>
								</div>
							</>
						)}

						{workExperiences.length > 0 && (
							<>
								<p>{t("workExperience")}</p>
								<div className="professional-experience view">
									<ul>
										{workExperiences.map((workExperience) => (
											<li key={workExperience.id}>
												<div className="icon">
													<img src={images.JobsWhite} alt="Jobs" />
												</div>
												<div className="details">
													<p>{workExperience.jobTitle}</p>
													<span>{workExperience.company}</span>
													<small>{`${formatDate(
														workExperience.startDate
													)} - ${formatDate(workExperience.endDate)} ${workExperience.address ? `| ${workExperience.address}` : ""}`}</small>
												</div>
											</li>
										))}
									</ul>
								</div>
							</>
						)}

						{educations.length > 0 && (
							<>
								<p>{t("education")}</p>
								<div className="education view">
									<ul>
										{educations.map((education) => (
											<li key={education.id}>
												<div className="icon">
													<img src={images.Education} alt="Education" />
												</div>
												<div className="details">
													<p>
														{t(
															enumToString(
																AcademicDegree,
																education.academicDegree ?? 0
															)
														)}
													</p>
													<span>{`${education.institution} ${
														education.fieldOfStudy
															? `| ${t(
																	enumToString(
																		FieldOfStudy,
																		education.fieldOfStudy ?? 0
																	)
																)}`
															: ""
													}`}</span>
													<small>{`${formatDate(
														education.startDate
													)} - ${formatDate(education.endDate)} ${education.address ? `| ${education.address}` : ""}`}</small>
												</div>
											</li>
										))}
									</ul>
								</div>
							</>
						)}

						{certificates.length > 0 && (
							<>
								<p>{t("certificateLicences")}</p>
								<div className="certificate view">
									<ul>
										{certificates.map((certificate) => (
											<li key={certificate.id}>
												<div className="icon">
													<img
														src={images.CertificateWhite}
														alt="certificate"
													/>
												</div>
												<div className="details">
													<p>{certificate.title}</p>
													<span>{certificate.description}</span>
													<small>
														{`${formatDate(certificate.startDate)} - ${formatDate(
															certificate.endDate
														)} ${certificate.institution ? `| ${certificate.institution}` : ""}`}
													</small>
												</div>
											</li>
										))}
									</ul>
								</div>
							</>
						)}
					</div>
					{/* my cv end */}
				</>
			)}

			{role === UserRoles.Recrewter && (
				<>
					{/* dream job start */}
					<div className="dream-job card" id="idealCandidate">
						<h6>{t("idealCandidate")}</h6>
						<Grid container spacing={{ xs: 4 }}>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("country")}:</span>
									<p>
										{t(enumToString(Country, idealCandidate?.country ?? 0))}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>
										{idealCandidate?.country === Country.Switzerland
											? t("canton")
											: t("state")}
										:
									</span>
									<p>{t(enumToString(State, idealCandidate?.state ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerEmployementType")}:</span>
									<p>
										{t(
											enumToString(
												EmploymentType,
												idealCandidate?.employmentType ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerAvailability")}:</span>
									<p>
										{t(
											enumToString(
												Availability,
												idealCandidate?.availability ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("position")}:</span>
									<p>
										{t(
											enumToString(
												JobPosition,
												idealCandidate?.jobPosition ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("workLoad")} %:</span>
									<p>
										{t(enumToString(Workload, idealCandidate?.workload ?? 0))}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerSalary")}:</span>
									<p>{t(enumToString(Salary, idealCandidate?.salary ?? 0))}</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("division")}:</span>
									<p>
										{t(
											enumToString(
												MedicalDivision,
												idealCandidate?.division ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
							<Grid size={{ sm: 6, md: 6, xl: 4, xs: 12 }}>
								<div className="job-detail">
									<span>{t("headerEducationLevel")}:</span>
									<p>
										{t(
											enumToString(
												EducationLevel,
												idealCandidate?.educationLevel ?? 0
											)
										)}
									</p>
								</div>
							</Grid>
						</Grid>
					</div>
					{/* dream job end */}
				</>
			)}
		</>
	);
};

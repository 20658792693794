import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/header/Header";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { useProfileImage } from "../../contexts/ImageContext";
import { useContext, useEffect, useState } from "react";
import {
	Availability,
	EducationLevel,
	EmploymentType,
	ImageType,
	JobPosition,
	Salary,
	Title,
	Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import images from "../../assets/images";
import { useNavigate, useParams } from "react-router-dom";
import { jobOfferDTO } from "../../core/dto/dto.models";
import { enumToString, formatUrlLink } from "../../core/helperConst";
import {
	createJobOfferAPI,
	deleteJobOfferAPI,
	getCompanyDetailsByJobOfferAPI,
	getCompanyLogoAPI,
	getJobDescriptionAPI,
	getJobOfferAPI,
	getProfilePhotoAPI,
	getRecrewterFromJobOfferAPI,
	isJobOfferPublishedAPI,
	toggleJobOfferAPI,
	updateJobOfferAPI,
} from "../../core/apiFunctions";
import { companyDetailsDTO, recrewterDTO } from "../../core/dto/user.models";
import { Country, State } from "../../config/Types/PlaceEnumDefinitions";
import TextField from "../../utils/TextField";
import RichTextEditor from "../../utils/RichTextEditor";
import DropdownField from "../../components/common/DropdownField";
import { MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import ModalContent from "../../utils/ModalContent";
import { AiOutlineCheck } from "react-icons/ai";
import { useLoading } from "../../contexts/LoadingContext";
import * as yup from "yup";
import { useSetState } from "../../utils/UseSetState";

const jobOfferFieldErrors = {
	title: "",
	companyDescription: "",
};

export const AddEditJobs = () => {
	const { images1 } = useProfileImage();
	const { setLoading, loading } = useLoading();

	const [isEditing, setIsEditing] = useState<boolean>(false);

	const { t } = useTranslation();
	const navigate = useNavigate();

	const [fieldErrors, setFieldErrors, getFieldErrors] =
		useSetState(jobOfferFieldErrors);
	// const [errors, setErrors] = useState<string[]>([]);

	// When a new Joboffer is created, in the url the jobofferId is 'create'
	const newJobOffer = "create";
	// Get the jobofferId from the url
	const { jobofferId } = useParams();

	const [header, setHeader] = useState<
		companyDetailsDTO & { coverImage?: string; image?: string }
	>();

	const [jobOffer, setJobOffer] = useState<jobOfferDTO>();

	const [isPublished, setIsPublished] = useState<boolean>(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// right side
	const [recrewter, setRecrewter] = useState<recrewterDTO>();
	const [recrewterProfilePicture, setRecrewterProfilePicture] =
		useState<string>();
	const [youTubeLink, setyouTubeLink] = useState("");
	const [companyLogo, setCompanyLogo] = useState<string>();
	const [companyDetails, setCompanyDetails] = useState<companyDetailsDTO>();
	const [jobTitle, setJobTitle] = useState("");

	// Handle onChange for dropdowns
	const onChange = (e: any) => {
		const { name, value } = e.target;

		const JobOfferDetail =
			name === "country"
				? { ...jobOffer!, [name]: value, state: 0 }
				: { ...jobOffer!, [name]: value };

		setJobOffer(JobOfferDetail);
		validateNestedSchema(JobOfferDetail);
	};

	const jobOfferSchema = yup.object().shape({
		title: yup.string().required("titleRequired"),
		companyDescription: yup.string().required("companyDescriptionRequired"),
	});

	const validateNestedSchema = async (jobOfferDetail = jobOffer) => {
		await jobOfferSchema
			.validate(jobOfferDetail, { abortEarly: false })
			.then(() => {
				setFieldErrors(jobOfferFieldErrors);
			})
			.catch((errors: yup.ValidationError) => {
				const validationErrors: any = {};
				errors.inner.forEach((error: any) => {
					validationErrors[error.path] = error.message;
				});
				setFieldErrors(validationErrors);
			});
	};

	async function handleRemove() {
		setIsPublished(false);
		if (jobofferId !== newJobOffer)
			await deleteJobOfferAPI(jobofferId !== undefined ? jobofferId : "").catch(
				() => {
					// joboffer not found
				}
			);
		navigate("/jobs");
	}

	async function handleSave() {
		if (jobOffer !== undefined) {
			await validateNestedSchema();
			if (Object.values(await getFieldErrors()).every((x) => x === "")) {
				var jobForUpload: jobOfferDTO = {
					id: jobOffer.id ?? "",
					title: jobOffer.title,
					description: jobOffer.description,
					workload: jobOffer.workload,
					salary: jobOffer.salary,
					employmentType: jobOffer.employmentType,
					country: jobOffer.country,
					state: jobOffer.state,
					jobPosition: jobOffer.jobPosition,
					educationLevel: jobOffer.educationLevel,
					division: jobOffer.division,
					availability: jobOffer.availability,
					jobType: jobOffer.jobType,
					companyUrl: jobOffer.companyUrl,
					discriminator: "Internal",
					createdDate: jobOffer.createdDate,
					lastUpdatedDate: jobOffer.lastUpdatedDate,
					isPublished: jobOffer.isPublished,
					recrewterId: jobOffer.recrewterId,
					companyDescription: jobOffer.companyDescription,
				};

				// check if the joboffer is new according to the url parameter
				if (jobofferId === newJobOffer) {
					// Save the job
					createJobOfferAPI(jobForUpload).then((response) => {
						jobForUpload.id = response.data;
						setJobOffer(jobForUpload);

						if (jobForUpload.id !== undefined) {
							navigate(`/jobs/${jobForUpload.id}`, { replace: true });
							setIsEditing(false);
						}
						window.scrollTo(0, 0);
					});
				}
				// The joboffer is not new
				else {
					// Update the joboffer
					jobForUpload.id = jobofferId !== undefined ? jobofferId : "";
					setLoading(true);
					updateJobOfferAPI(jobForUpload)
						.then((response) => {
							// Update the job
							jobForUpload.id = response.data;
							updateJobOfferAPI(jobForUpload);
							setIsEditing(false);
						})
						.finally(() => setLoading(false));
				}
			}
		}
	}

	function handlePublishJobOffer() {
		//call toggle
		setLoading(true);
		toggleJobOfferAPI(jobofferId!)
			.then((response) => {
				setIsPublished(response.data === true ? true : false);
			})
			.finally(() => setLoading(false));
	}

	useEffect(() => {
		// Fetch the job offer details
		window.scrollTo(0, 0);
		const fetchDescription = async () => {
			const jobDescription = await getJobDescriptionAPI(jobofferId || "");

			setJobTitle(jobDescription.data.title);
		};
		fetchDescription();

		const fetchHeader = async () => {
			const companydetails = await getCompanyDetailsByJobOfferAPI(jobofferId);

			setHeader(companydetails.data);
		};
		fetchHeader();

		// To show which company created the job offer
		const fetchCompanyDetails = async () => {
			const companyDetails = await getCompanyDetailsByJobOfferAPI(jobofferId);

			companyDetails.data.youTubeUrl = correctYouTubeLink(
				companyDetails.data.youTubeUrl
			);

			// Iframe can't hance the watch?v= in the link, so we need to replace it with embed/
			setyouTubeLink(companyDetails.data.youTubeUrl);
			setCompanyDetails(companyDetails.data);

			// To get the company logo
			getCompanyLogoAPI(companyDetails.data.id).then((response) => {
				const url = URL.createObjectURL(response.data);
				setCompanyLogo(url);
			});
		};
		setLoading(true);
		fetchCompanyDetails().finally(() => setLoading(false));

		const fetchRecrewter = async () => {
			const jobOfferCreator = await getRecrewterFromJobOfferAPI(jobofferId);
			// get image from user by id
			getProfilePhotoAPI(jobOfferCreator.data.userId).then((response) => {
				if (response && response.data !== null) {
					const url = URL.createObjectURL(response.data);
					setRecrewterProfilePicture(url);
				}
			});
			setRecrewter(jobOfferCreator.data);
		};
		fetchRecrewter();

		const fetchIsPublished = async () => {
			isJobOfferPublishedAPI(jobofferId!).then((response) => {
				setIsPublished(response.data);
			});
		};
		if (jobofferId !== newJobOffer) fetchIsPublished();

		const fetchInitalData = async () => {
			await getJobOfferAPI(jobofferId!).then((response) => {
				setJobOffer({ ...response.data });
			});
		};
		if (jobofferId !== newJobOffer) fetchInitalData();
	}, []);

	useEffect(() => {
		if (jobofferId === newJobOffer) {
			var jobwithjoboffer: jobOfferDTO = {
				isPublished: isPublished,
				recrewterId: "",
				workload: jobOffer?.workload || 0,
				salary: jobOffer?.salary || 0,
				employmentType: jobOffer?.employmentType || 0,
				country: jobOffer?.country || 0,
				jobPosition: jobOffer?.jobPosition || 0,
				jobType: jobOffer?.jobType || 0,
				division: jobOffer?.division || 0,
				availability: jobOffer?.availability || 0,
				state: jobOffer?.state || 0,
				educationLevel: jobOffer?.educationLevel || 0,
				companyUrl: companyDetails?.youTubeUrl,
				id: jobofferId,
				title: jobOffer?.title,
				description: jobOffer?.description,
				companyDescription: companyDetails?.description,
				discriminator: jobOffer?.discriminator ?? "Offer",
				createdDate: jobOffer?.createdDate,
				lastUpdatedDate: jobOffer?.lastUpdatedDate,
			};
			setJobOffer(jobwithjoboffer);
		}
	}, [companyDetails]);

	function correctYouTubeLink(link: string): string {
		const regex =
			/(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
		const match = link.match(regex);

		if (match) {
			const videoId = match[1];
			return `https://www.youtube.com/embed/${videoId}`;
		}
		return link;
	}

	return (
		<>
			<Helmet>
				<title>
					{t("myProfile")} | {t("reCREWter")}
				</title>
			</Helmet>

			{/* wrapper start */}
			<div className="wrapper">
				{/* sidebar start */}
				<Sidebar />
				{/* sidebar end */}

				{/* main start */}
				<main>
					{/* header start */}
					<Header />
					{/* header end */}

					{/* profile wrapper start */}
					<div className="inner-wrapper profile-wrapper">
						{/* left start */}
						<div className="left">
							{/* user info start  */}
							<div className="user-info card">
								<div
									className="thumbnail"
									style={{
										backgroundImage: `url(${images1[ImageType.HeaderImage]})`,
										display: "flex",
										alignItems: "end",
										justifyContent: "end",
										padding: "20px",
									}}
									onClick={() => {}}
								></div>
								{/* content start */}
								<div className="content">
									<img src={companyLogo} alt={""} onClick={() => {}} />

									{/* user details start */}
									<div className="user-details">
										<div className="top">
											<div className="user-name" style={{ width: "100%" }}>
												<Grid
													container
													sx={{ width: "100%" }}
													spacing={{ xs: 3, md: 2 }}
													paddingBlock={"15px"}
													justifyContent={"space-between"}
												>
													<Grid size={{ sm: 5, md: 6, xs: 12 }}>
														<h4>{header?.name}</h4>
														<p></p>
														<span className="location">
															<img src={images.Location} alt="Location" />
															<span>{header?.address}</span>
														</span>
													</Grid>
													<Grid
														size={{ sm: 7, xs: 12, md: 5, lg: 6 }}
														className={`job-ad-message ${!isPublished ? "not-publish" : ""}`}
													>
														{jobofferId !== newJobOffer && !loading ? (
															!isPublished ? (
																<>
																	<p>{t("jobAdvHeaderAlertMsgLine1")}</p>
																	<p>{t("jobAdvHeaderAlertMsgLine2")}</p>
																	<AiOutlineCheck />
																</>
															) : (
																<>
																	<p>{t("jobAdvHeaderPublishedMessage")}</p>
																</>
															)
														) : (
															<></>
														)}
													</Grid>
												</Grid>
												<Grid
													container
													sx={{ width: "100%" }}
													spacing={2}
													justifyContent={"end"}
												>
													<Grid
														size={{ xs: 12 }}
														container
														spacing={3}
														justifyContent={{ xs: "center", sm: "end" }}
														className="buttonGrid"
													>
														<Grid
															size={{
																xs:
																	jobofferId === newJobOffer
																		? "grow"
																		: isPublished
																			? 5
																			: "grow",
																sm: "auto",
															}}
														>
															<Button
																type="button"
																fullWidth
																color="secondary"
																variant="contained"
																onClick={() => setShowDeleteModal(true)}
															>
																{t("delete")}
															</Button>
														</Grid>
														{jobofferId !== newJobOffer && (
															<Grid
																size={{
																	xs: "grow",
																	sm: "auto",
																}}
															>
																<Button
																	fullWidth
																	variant="contained"
																	color="primary"
																	title={t("publish")}
																	onClick={handlePublishJobOffer}
																	className="editButton"
																>
																	<span>
																		{isPublished
																			? t("hideJobOffer")
																			: t("publish")}
																	</span>
																</Button>
															</Grid>
														)}

														<Grid
															size={{
																xs: jobofferId === newJobOffer ? "grow" : 12,
																sm: "auto",
															}}
														>
															{jobofferId === newJobOffer || isEditing ? (
																<Button
																	fullWidth
																	type="button"
																	color="primary"
																	title={
																		jobofferId === newJobOffer
																			? t("preview")
																			: t("save")
																	}
																	variant="outlined"
																	onClick={handleSave}
																>
																	<span>
																		{jobofferId === newJobOffer
																			? t("preview")
																			: t("save")}
																	</span>
																</Button>
															) : (
																<Button
																	fullWidth
																	variant="outlined"
																	title={t("edit")}
																	onClick={() => {
																		setIsEditing(true);
																		window.scrollTo(0, 0);
																	}}
																	className="editButton"
																>
																	<img src={images.Edit} alt="Edit" />
																	<span>{t("edit")}</span>
																</Button>
															)}
														</Grid>
													</Grid>
												</Grid>
											</div>
										</div>
									</div>
									{/* user details end */}
								</div>
								{/* content end */}
							</div>
							{/* user info end  */}

							{!isEditing && jobofferId !== newJobOffer ? (
								<>
									<div className="card my-cv" id="myCv">
										<h6>{t("jobTitle")}</h6>
										<span className="job-title">{jobOffer?.title || "-"}</span>
									</div>
									<div className="card my-cv" id="myCv">
										<h6>{t("companyDescription")}</h6>
										<span>{jobOffer?.companyDescription || "-"}</span>
									</div>
									<div className="card my-cv" id="myCv">
										<h6>{t("jobDescription")}</h6>
										<RichTextEditor
											value={jobOffer?.description}
											readOnly={true}
											className="jobDescriptionEditor"
										/>
									</div>
								</>
							) : (
								<div className={`card my-cv JobDetailFields`} id="myCv">
									<TextField
										displayName="jobTitle"
										field="title"
										placeholder={t("jobTitle")}
										value={jobOffer?.title}
										onChange={onChange}
										formFloating={true}
										validationMessage={fieldErrors.title}
										isMandatory={true}
									/>

									<TextField
										displayName="companyDescription"
										as="textarea"
										field="companyDescription"
										placeholder={t("companyDescription")}
										value={jobOffer?.companyDescription}
										onChange={onChange}
										rows={5}
										isMultiline
										validationMessage={fieldErrors.companyDescription}
										formFloating={true}
										isMandatory={true}
									/>

									<div className="rich-text-editor-wrapper">
										<p>{t("jobDescription")}</p>
										<RichTextEditor
											value={jobOffer?.description}
											onChange={(value, delta, source, editor) =>
												setJobOffer((prev) => ({
													...prev!,
													description: value,
												}))
											}
											readOnly={false}
											className="jobDescriptionEditor"
										/>
									</div>
								</div>
							)}
						</div>

						{/* left end */}

						{/* right start */}
						<div className="right add-edit-right">
							<div className="card responsible-information">
								<h6>{t("responsible")}</h6>
								<div className="company-user-profile">
									<img src={recrewterProfilePicture} alt="" />
									<div>
										<span>{recrewter?.name}</span>
										<p>{t("company")}</p>
									</div>
								</div>
								<ul className="social-information">
									<li>
										<div className="icon">
											<img src={images.LinkedinPrimary} alt="LinkedIn" />
										</div>
										<span>
											<span>
												{companyDetails?.linkedIn ? (
													<Link
														href={formatUrlLink(companyDetails?.linkedIn)}
														target="_blank"
													>
														{companyDetails?.linkedIn}
													</Link>
												) : (
													"-"
												)}
											</span>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Xing} alt="xing" />
										</div>
										<span>
											<span>
												{companyDetails?.xing ? (
													<Link
														href={formatUrlLink(companyDetails?.xing)}
														target="_blank"
													>
														{companyDetails?.xing}
													</Link>
												) : (
													"-"
												)}
											</span>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Globe} alt="Globe" />
										</div>
										<span>
											<span>
												{companyDetails?.website ? (
													<Link
														href={formatUrlLink(companyDetails?.website)}
														target="_blank"
													>
														{companyDetails?.website}
													</Link>
												) : (
													"-"
												)}
											</span>
										</span>
									</li>
									<li>
										<div className="icon">
											<img src={images.Email} alt="Email" />
										</div>
										<span>
											<span>
												{companyDetails?.email ? (
													<Link
														href={"mailto:" + companyDetails?.email}
														target="_blank"
													>
														{companyDetails?.email}
													</Link>
												) : (
													"-"
												)}
											</span>
										</span>
									</li>
								</ul>
								{isEditing || jobofferId === newJobOffer ? (
									<div className="ideal-candidate-dropdowns-wrapper">
										<DropdownField
											displayName="headerDivision"
											field="division"
											enumType={MedicalDivision}
											selected={jobOffer?.division}
											onChange={onChange}
										/>
										<DropdownField
											displayName="headerEducationLevel"
											field="educationLevel"
											enumType={EducationLevel}
											selected={jobOffer?.educationLevel!}
											onChange={onChange}
										/>
										<DropdownField
											displayName="headerPosition"
											field="jobPosition"
											enumType={JobPosition}
											selected={jobOffer?.jobPosition!}
											onChange={onChange}
										/>
										<DropdownField
											displayName="headerEmployementType"
											field="employmentType"
											enumType={EmploymentType}
											selected={jobOffer?.employmentType!}
											onChange={onChange}
										/>
										<DropdownField
											displayName="pensum"
											field="workload"
											enumType={Workload}
											selected={jobOffer?.workload!}
											onChange={onChange}
										/>
										<DropdownField
											displayName="headerAvailability"
											field="availability"
											enumType={Availability}
											selected={jobOffer?.availability!}
											onChange={onChange}
										/>
										<DropdownField
											displayName="headerSalary"
											field="salary"
											enumType={Salary}
											selected={jobOffer?.salary!}
											onChange={onChange}
										/>
										<DropdownField
											displayName="headerCountry"
											field="country"
											enumType={Country}
											selected={jobOffer?.country!}
											onChange={onChange}
										/>
										{jobOffer?.country === Country.Switzerland ? (
											<DropdownField
												displayName="canton"
												field="state"
												enumType={State}
												selected={jobOffer?.state!}
												onChange={onChange}
												disabled={jobOffer?.country !== Country.Switzerland}
												index={21600}
												indexSize={27}
											/>
										) : jobOffer?.country === Country.Germany ? (
											<DropdownField
												displayName="state"
												field="state"
												enumType={State}
												selected={jobOffer?.state!}
												onChange={onChange}
												disabled={jobOffer?.country !== Country.Germany}
												index={8400}
												indexSize={17}
											/>
										) : jobOffer?.country === Country.Austria ? (
											<DropdownField
												displayName="state"
												field="state"
												enumType={State}
												selected={jobOffer?.state!}
												onChange={onChange}
												disabled={jobOffer?.country !== Country.Austria}
												index={1500}
												indexSize={10}
											/>
										) : (
											<DropdownField
												displayName="state"
												field=""
												enumType={State}
												selected={0}
												onChange={onChange}
												disabled={true}
											/>
										)}
									</div>
								) : (
									<ul className="ideal-candidate ideal-candidate-list">
										<li>
											<div className="icon">
												<img src={images.Division} alt="Location" />
											</div>
											<span>
												<small>{t("headerDivision")}</small>
												<span>
													{jobOffer?.division
														? t(
																enumToString(MedicalDivision, jobOffer.division)
															)
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.EducationGreen} alt="Education" />
											</div>
											<span>
												<small>{t("headerEducationLevel")}</small>
												<span>
													{jobOffer?.educationLevel
														? t(
																enumToString(
																	EducationLevel,
																	jobOffer.educationLevel
																)
															)
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Badge} alt="Badge" />
											</div>
											<span>
												<small>{t("position")}</small>
												<span>
													{jobOffer?.jobPosition
														? t(enumToString(JobPosition, jobOffer.jobPosition))
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.JobsActive} alt="Job" />
											</div>
											<span>
												<small>{t("preferredTypeOfEmployment")}</small>
												<span>
													{jobOffer?.employmentType
														? t(
																enumToString(
																	EmploymentType,
																	jobOffer.employmentType
																)
															)
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.JobTick} alt="Job" />
											</div>
											<span>
												<small>{t("workLoad")}</small>
												<span>
													{jobOffer?.workload
														? t(enumToString(Workload, jobOffer.workload))
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.ClockActive} alt={"clock"} />
											</div>
											<span>
												<small>{t("headerAvailability")}</small>
												<span>
													{jobOffer?.availability
														? t(
																enumToString(
																	Availability,
																	jobOffer.availability
																)
															)
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Money} alt="Salary" />
											</div>
											<span>
												<small>{t("headerSalary")}</small>

												<span>
													{jobOffer?.salary
														? t(enumToString(Salary, jobOffer.salary))
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Globe} alt="Globe" />
											</div>
											<span>
												<small>{t("country")}</small>
												<span>
													{jobOffer?.country
														? t(enumToString(Country, jobOffer.country))
														: "-"}
												</span>
											</span>
										</li>
										<li>
											<div className="icon">
												<img src={images.Location} alt="Location" />
											</div>
											<span>
												<small>
													{Country.Switzerland === jobOffer?.country
														? t("canton")
														: t("state")}
												</small>
												<span>
													{jobOffer?.state
														? t(enumToString(State, jobOffer.state))
														: "-"}
												</span>
											</span>
										</li>
									</ul>
								)}
							</div>
							<div className="card">
								<h6 className="company-video-title">{t("companyVideo")}</h6>
								<div className="company-video">
									{isEditing || jobofferId === newJobOffer ? (
										<>
											<div className="field-label">
												<img src={images.YouTube} alt="YouTube" />
												<p>{t("youtube")}</p>
											</div>
											<TextField
												type="text"
												class="jobAdvInput"
												field="companyUrl"
												placeholder={t("copyPasteLink")}
												value={jobOffer?.companyUrl}
												onChange={(evnt) => {
													setyouTubeLink(correctYouTubeLink(evnt.target.value));
													setJobOffer((prev) => ({
														...prev!,
														companyUrl: correctYouTubeLink(evnt.target.value),
													}));
												}}
												onBlur={(evnt) => evnt.currentTarget.value}
												disabled={!recrewter?.isManager}
												onClick={(e) => {
													const target = e.target as HTMLInputElement;
													target.select();
												}}
												// only managers can edit the link
											/>
											<iframe
												title="youtube-video-editMode"
												className="youtube-video"
												src={youTubeLink}
											/>
										</>
									) : (
										<>
											<div className="field-label">
												<img src={images.YouTube} alt="YouTube" />
												<p>{t("youtube")}</p>
											</div>
											<iframe
												title="youtube-video-viewMode"
												className="youtube-video"
												src={youTubeLink}
											/>
										</>
									)}
								</div>
							</div>
						</div>
						{/* right end */}
					</div>
					{/* profile wrapper end */}
				</main>
				{/* main end */}
				<ModalContent
					title={t("confirmation")}
					size="sm"
					show={showDeleteModal}
					onHideCallbackPrimary={() => {
						handleRemove();
						setShowDeleteModal(false);
					}}
					onHideCallbackThird={() => setShowDeleteModal(false)}
					onClose={() => {
						setShowDeleteModal(false);
					}}
					primaryButton={t("yes")}
					thirdButton={t("no")}
					secondaryButtonColor="secondary"
				>
					<Box pl={2}>
						<p>{t("deleteJobOfferAlertMsg")}</p>
					</Box>
				</ModalContent>
			</div>
			{/* wrapper end */}
		</>
	);
};

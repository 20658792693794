import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import images from "../../assets/images";
import {
	ImageType,
	UserRoles,
} from "../../config/Types/GeneralEnumDefinitions";
import { useProfileImage } from "../../contexts/ImageContext";
import { useLoading } from "../../contexts/LoadingContext";
import {
	getCompanyNameForRecrewterAPI,
	getUserDetailsAPI,
} from "../../core/apiFunctions";
import { checkRole, getEmail, getName } from "../../core/claimFunctions";
import { logout } from "../../core/handleJWT";
import AuthenticationContext from "../auth/AuthenticationContext";
import PasswordChangeContent from "../changePassword/ChangePassword";
import DeleteAccount from "../deleteAccount/DeleteAccount";
import NotificationSetting from "../notificationSetting/NotificationSetting";

const Header = () => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const { setLoading } = useLoading();

	const [languageMenuEl, setLanguageMenuEl] =
		React.useState<null | HTMLElement>(null);
	const [userMenuEl, setUserMenuEl] = React.useState<null | HTMLElement>(null);
	const [showModalFor, setShowModalFor] = useState<
		"" | "changePassword" | "deleteAccount" | "notification"
	>("");
	const [isEmailEnabled, setIsEmailEnabled] = useState(false);
	const [isPushEnabled, setIsPushEnabled] = useState(false);

	const languageMenuOpen = Boolean(languageMenuEl);
	const userMenuOpen = Boolean(userMenuEl);

	const LANGUAGES = [
		{
			value: "en",
			label: "English",
			icn: images.Flag,
		},
		{
			value: "de",
			label: "Deutsch",
			icn: images.DeutschLangLogo,
		},
	];

	const PROFILE_MENU = [
		{
			label: t("myProfile"),
			icn: images.MyProfile,
			to: "/profile",
		},
		{
			label: t("changePassword"),
			icn: images.ChangePassword,
			onClick: () => {
				setShowModalFor("changePassword");
			},
		},
		{
			label: t("deleteAccount"),
			icn: images.DeleteUser,
			onClick: () => {
				setShowModalFor("deleteAccount");
			},
		},
		{
			label: t("logout"),
			icn: images.Logout,
			onClick: logout,
		},
	];
	const handleLanguageMenuOpen = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		setLanguageMenuEl(event.currentTarget);
	};

	const handleLanguageMenuClose = (lng: string) => {
		setLanguageMenuEl(null);
		if (typeof lng == "string") i18n.changeLanguage(lng ?? "en");
	};

	const handleuserMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setUserMenuEl(event.currentTarget);
	};

	const handleuserMenuClose = () => {
		setUserMenuEl(null);
	};

	const handleSidebarToggle = () => {
		document.body.classList.toggle("toggle-sidebar");
	};

	const handleModalClose = () => {
		setShowModalFor("");
	};

	const { claims } = useContext(AuthenticationContext);
	const { images1 } = useProfileImage();

	const companyNameKey = "company-name";

	useEffect(() => {
		getAndSetCompanyName();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [claims]);

	const setMailAsCompanyName = () => {
		const mail = getEmail(claims);
		localStorage.setItem(companyNameKey, mail);
	};

	const getAndSetCompanyName = () => {
		if (checkRole(claims, UserRoles.Recrewter)) {
			getCompanyNameForRecrewterAPI().then((response) => {
				if (response.data === "") {
					setMailAsCompanyName();
				} else {
					localStorage.setItem(companyNameKey, response.data);
				}
			});
		} else {
			setMailAsCompanyName();
		}
	};

	const handleOpenNotificationPopup = async () => {
		try {
			setLoading(true);
			const response = await getUserDetailsAPI();
			setShowModalFor("notification");
			setIsEmailEnabled(response.data.emailNotificationsEnabled);
			setIsPushEnabled(response.data.pushNotificationsEnabled);
		} catch (error) {
			console.error("Error fetching user details:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{/* header start */}
			<header>
				{/* left start */}
				<div className="left">
					<Link to="/dashboard" title="reCREWter">
						<img src={images.Logo} alt="reCREWter" />
						<img
							src={images.MobileLogo}
							className="mobile-logo"
							alt="reCREWter"
						/>
					</Link>
					<IconButton title="Menu" onClick={handleSidebarToggle}>
						<img src={images.Hamburger} alt="Hamburger" />
					</IconButton>
					{/* <FormControl>
						<OutlinedInput
							placeholder={t("searchHere")}
							startAdornment={
								<InputAdornment position="start">
									<img src={images.Search} alt="Search" />
								</InputAdornment>
							}
						/>
					</FormControl> */}
				</div>
				{/* left end */}

				{/* right start */}
				<div className="right">
					<div className="btn-group">
						<IconButton
							title={t("notifications")}
							onClick={handleOpenNotificationPopup}
						>
							<img src={images.Bell} alt="Bell" />
						</IconButton>
					</div>

					{/* language menu start */}
					<div className="language-menu">
						<Button
							aria-controls={languageMenuOpen ? "language-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={languageMenuOpen ? "true" : undefined}
							onClick={handleLanguageMenuOpen}
							variant="text"
							color="inherit"
							title={t(
								LANGUAGES.find((x) => i18n.language.includes(x.value))?.label ??
									""
							)}
						>
							<img
								src={
									LANGUAGES.find((x) => i18n.language.includes(x.value))?.icn ||
									LANGUAGES.find((x) => x.value === "en")?.icn
								}
								className="flag"
								alt="English"
							/>
							<span>
								{LANGUAGES.find((x) => i18n.language.includes(x.value))
									?.label || LANGUAGES.find((x) => x.value === "en")?.label}
							</span>
							<img src={images.DownArrow} className="arrow" alt="Arrow Down" />
						</Button>
						<Menu
							id="language-menu"
							className="language-menu"
							anchorEl={languageMenuEl}
							open={languageMenuOpen}
							onClose={handleLanguageMenuClose}
						>
							{LANGUAGES.map((x, i) => {
								return (
									<MenuItem
										onClick={() => handleLanguageMenuClose(x.value)}
										key={`lng-${i}`}
									>
										<img src={x.icn} alt={x.label} />
										<span>{x.label}</span>
									</MenuItem>
								);
							})}
						</Menu>
					</div>
					{/* language menu end */}

					<div className="separator"></div>

					{/* user menu start */}
					<div className="user-menu">
						<Button
							aria-controls={userMenuOpen ? "user-menu" : undefined}
							aria-haspopup="true"
							aria-expanded={userMenuOpen ? "true" : undefined}
							onClick={handleuserMenuOpen}
							variant="text"
							color="inherit"
							title={getName(claims)}
						>
							<img
								// src={images.UserImg}
								src={images1[ImageType.ProfileImage]}
								className="user-img"
								alt={getName(claims)}
							/>
							<span className="user-name">
								<span>{getName(claims)}</span>
								<small>
									{checkRole(claims, UserRoles.Admin)
										? t("administrator")
										: checkRole(claims, UserRoles.Recrewter)
											? t("company")
											: checkRole(claims, UserRoles.Employee)
												? t("talentProfessional")
												: ""}
								</small>
							</span>
							<img src={images.DownArrow} className="arrow" alt="Arrow Down" />
						</Button>
						<Menu
							id="user-menu"
							className="user-menu"
							anchorEl={userMenuEl}
							open={userMenuOpen}
							onClose={handleuserMenuClose}
						>
							{PROFILE_MENU.map((x, i) => {
								return (
									<MenuItem
										onClick={() => {
											handleuserMenuClose();
											if (x.onClick) {
												x.onClick();
											}
										}}
										key={`profile-menu-${i}`}
									>
										<Link
											to={x.to ?? ""}
											title={t(x.label)}
											onClick={(e) => (x.onClick ? e.preventDefault() : "")}
										>
											<img src={x.icn} alt="Profile" />
											<span>{x.label}</span>
										</Link>
									</MenuItem>
								);
							})}
						</Menu>
					</div>
					{/* user menu end */}
				</div>
				{/* right start */}
			</header>
			{/* header end */}

			{showModalFor && (
				<>
					{showModalFor === "changePassword" ? (
						<PasswordChangeContent
							handleClose={handleModalClose}
							isOpen={true}
						/>
					) : showModalFor === "deleteAccount" ? (
						<DeleteAccount handleClose={handleModalClose} isOpen={true} />
					) : showModalFor === "notification" ? (
						<NotificationSetting
							isOpen={true}
							handleClose={handleModalClose}
							isEmailEnabled={isEmailEnabled}
							isPushEnabled={isPushEnabled}
							setIsEmailEnabled={setIsEmailEnabled}
							setIsPushEnabled={setIsPushEnabled}
						/>
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
};

export default Header;
